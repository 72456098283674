import React ,{ useEffect}from 'react'
import { useNavigate } from "react-router-dom";
import { jwtDecode } from 'jwt-decode';

function Wrapper({children}) {

    const navigate = useNavigate();

    useEffect(()=>{
        var token = sessionStorage.getItem("TOKEN"); 
        var tokenVerify = null;
        if(token){
            try{
                let decodedToken = jwtDecode(token);
                let currentDate = new Date();
                if(decodedToken.exp * 1000 < currentDate.getTime()){
                    tokenVerify = false;
                }else{
                    tokenVerify = true;
                }
            }catch(error){
                navigate('/login');
            }  
        }

        if(!token || tokenVerify === false ){
            navigate('/login');
        }
    },[navigate])

    return <>{children}</>;
}

export default Wrapper