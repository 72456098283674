import axios from 'axios';

export const MakeNetworkCall = (url, method, params, callback) => {
    return axios({
        method: method,
        url: url,
        data: params,
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${sessionStorage.getItem('TOKEN')}`, 
        }
    })
    .then(response => {
        console.log('========MakeNetworkCall:response:',response.data);
        callback({status:response.data.status,data:response.data.data,message:response.data.message});
    })
    .catch(error => {
        console.log('========MakeNetworkCall:error:',error);
        callback({status:false,error:error,data:[]});
    });
};
