// Mobile
// const Access = "http://";
// const Host = "192.168.1.16:8000";
// const Env = "/api";

// production
const Access = "https://";     
const Host = "prod.viventure.inaipi.ae";
const Env = "/api/api";

// release
// const Access = "https://";     
// const Host = "viventure.inaipi.ae";
// const Env = "/api/api";






export const postLoginUrl = Access + Host + Env + "/user/login";
export const getAdminsUrls = Access + Host + Env + "/user/getadmins";
export const getStateHeadUrls = Access + Host + Env + "/user/getstatehead";
export const getManagersUrls = Access + Host + Env + "/user/getmanagers";
export const getgetRepresentativeUrls = Access + Host + Env + "/user/getrepresentative";
export const postUsersUrls = Access + Host + Env + "/user/addnewuser";
export const postUpdateUserUrls = Access + Host + Env + "/user/updateuser";
export const postSuperAdminUpdateUserUrls = Access + Host + Env + "/user/superupdateuser";
export const getDistrictsUrls = Access + Host + Env + "/common/getdistricts";
export const postPlacesUrls = Access + Host + Env + "/common/getplaces";
export const postSalesUrl = Access + Host + Env + "/sale/create";
export const postEditSalesUrl = Access + Host + Env + "/sale/edit";
export const getSalesListUrl = Access + Host + Env + "/sale/retrieve";
export const postSalesDetailsUrl = Access + Host + Env + "/sale/getsaledetails";
export const postSalesDeletesUrl = Access + Host + Env + "/sale/delete";
export const getPaymentStatusUrls = Access + Host + Env + "/common/getpaymentstatus";
export const postFilterSaleUrls = Access + Host + Env + "/sale/salefilter";
export const getAllRepresentiveUrls = Access + Host + Env + "/common/getallrepresentive";
export const getAllStateHeadsUrls = Access + Host + Env + "/user/getallstateheads";
export const getAllManagersUrls = Access + Host + Env + "/user/getallmanagers";
export const getAllSuperAdminUserEditDetailsUrls = Access + Host + Env + "/user/getsuperadminusersdetail";

//New APIS
export const getAdminHomeUrls = Access + Host + Env + "/user/getadminhome";
export const getStateHeadHomeUrls = Access + Host + Env + "/user/getstateheadhome";
export const getManagersHomeUrls = Access + Host + Env + "/user/getmanagershome";
export const getRepresentativeHomeUrls = Access + Host + Env + "/user/getrepresentativehome";
export const getUsersListUrls = Access + Host + Env + "/user/getuserslist";
export const getDeleteUsersUrls = Access + Host + Env + "/user/deleteuser";
export const getUsersDetailsUrls = Access + Host + Env + "/user/getusersdetail";
export const postStateHeadFilterSaleUrls = Access + Host + Env + "/sale/salestateheadfilter";
export const postFilterManagerSaleUrls = Access + Host + Env + "/sale/salemanagerfilter";
export const postFilterRepSaleUrls = Access + Host + Env + "/sale/saleRepfilter";
export const getRepoByLocationID = Access + Host + Env + "/user/getrepbylocation";

//product apis
export const processCreateApi = Access + Host + Env + "/product/create";
export const productlistApi = Access + Host + Env + "/product/list";
export const productDelete = Access + Host + Env + "/product/delete";
export const singleProductApi = Access + Host + Env + "/product/productbyid";
export const updateProductApi = Access + Host + Env + "/product/edit";

//pdf 
export const productPdf = Access + Host + Env + "/product/productreport";

//new pdf format
export const newproductPdf = Access + Host + Env + "/product/productreportbymonth";
export const getDistrictsPdf = Access + Host + Env + "/common/getdistrictsbyuser";
export const getRepsPdf = Access + Host + Env + "/common/getrepsbymanager";


