import {Routes, Route, HashRouter} from 'react-router-dom'
import '../Assets/css/App.css';
import PageNotFound from './PageNotFound';
import Admin from './AdminHome';
import Superadminhome from './Superadminhome';
import Login from './Login';
import UserManagement from './UserManagement';
import StateHead from './StateHead';
import Managershome from './Managershome';
import Representative from './Representative';
import ProductList from './ProductList';
import Pdf from './Pdf'
import Wrapper from '../Wrapper';
import { Toaster } from 'react-hot-toast';

function App() {
    return (
      <HashRouter >
      <Wrapper>
        <Routes basename="/">
                <Route path='/login' element={<Login/>}/>
                <Route path='/superadmin' element={<Superadminhome/>}/>
                <Route path='/admin' element={<Admin/>}/>
                <Route path='/usermanagement' element={<UserManagement/>}/>
                <Route path='/statehead' element={<StateHead/>}/>
                <Route path='/managershome' element={<Managershome/>}/>
                <Route path='/representative' element={<Representative/>}/>
                <Route path='/productlist' element={<ProductList/>}/>     
                <Route path='/pdf' element={<Pdf/>}/>
                <Route path='*' element={<PageNotFound/>}/>
            </Routes>
            <Toaster/>
      </Wrapper>
          
      </HashRouter>
    );
}

export default App;
