import React, {useState,useEffect} from 'react';
import '../Assets/css/App.css';
import {Link, useNavigate} from 'react-router-dom';
import {postLoginUrl} from '../Commons/Urls';
import { MakeNetworkCall } from '../Commons/MakeNetworkCall';

import Logo from '../Assets/images/logo.png';
import Eye from '../Assets/images/icon.png';
import Img1 from '../Assets/images/sign2.png';
import Img2 from '../Assets/images/sign1.png';
import Img3 from '../Assets/images/sign3.png';
import Hide from '../Assets/images/hide.png';





function Login() {
    const navigate = useNavigate();
    const [getEmail, setEmail] = useState("");
    const [getPassword, setPassword] = useState("");
    const [getInputError, setInputError] = useState(false);
    const [getInputErrorMessage, setInputErrorMessage] = useState("");

    const [screenWidth, setScreenWidth] = useState(window.innerWidth);


    useEffect(() => {
        const handleResize = () => { setScreenWidth(window.innerWidth);  };
          window.addEventListener('resize', handleResize);
          return () => { window.removeEventListener('resize', handleResize); };
     }, []);

     useEffect(()=>{
        var token = sessionStorage.getItem("TOKEN"); 
        if(token){
            switch (sessionStorage.getItem("USER_ROLE")) {
                case "1":navigate('/superadmin'); break;
                case "2":navigate('/admin'); break;
                case "3":navigate('/statehead'); break;
                case "4":navigate('/managershome'); break;
                case "5":navigate('/representative'); break;
                default:
                    break;
            }
        }
      },[])

    const handleUsernameChange = (e) => {  setEmail(e.target.value) };
    const handlePasswordChange = (e) => { setPassword(e.target.value) };
    function clearAllFields(){
        setEmail("");
        setPassword("");
    }
    const loginCallBack = (res) => {
        console.log('=========status:callback:',res.status);
        setInputError(false);
        if(res.status && res.data.length != 0){
            console.log('=========status:callback:',res);
            sessionStorage.setItem('USER_ID', res.data.user.id);
            sessionStorage.setItem('USER_NAME', res.data.user.name);
            sessionStorage.setItem('USER_EMAIL', res.data.user.email);
            sessionStorage.setItem('USER_ROLE', res.data.user.role);
            sessionStorage.setItem('TOKEN', res.data.token);
            clearAllFields();
            switch (res.data.user.role) {
                case "1":navigate('/superadmin'); break;
                case "2":navigate('/admin'); break;
                case "3":navigate('/statehead'); break;
                case "4":navigate('/managershome'); break;
                case "5":navigate('/representative'); break;
                default:
                    setInputError(true);
                    setInputErrorMessage("You are not autherized to perform...!");
                    break;
            }
        }else{
            console.log('=========status!error:',res.error);
            console.log('=========status!callback:',res.error != undefined ? res.error.message == "Request failed with status code 401" ? "Invalied  username or password" : res.error.message : "Invalid access with wrong credentals");
            setInputError(true);
            setInputErrorMessage(
                res.error != undefined ? 
                res.error.message == "Request failed with status code 401" ? "Invalied  username or password" : 
                res.error.message == "Request failed with status code 500" ? "Network Error" : 
                res.error.message : 
                "Invalid access with wrong credentals"
            );
        }
    }
    function handleSubmit(){
        try {
            if(getEmail.length != 0){
                if(getPassword.length != 0){
                    console.log('=========Url:',postLoginUrl);
                    const obj = {
                        email: getEmail,
                        password: getPassword
                    };

                    MakeNetworkCall(postLoginUrl,'POST',obj,loginCallBack);
                }else{
                    setInputError(true);
                    setInputErrorMessage("Enter Password..!");
                }
            }else{
                setInputError(true);
                setInputErrorMessage("Enter Email..!");
            }
        } catch (error) {
            console.log('There was a problem with the API call:', error);
            setInputError(true);
            setInputErrorMessage("Connection Error...!");
        }
    };
//password hide or show
 const [showPassword, setShowPassword] = useState(false);
 const togglePasswordVisible=() =>{
    setShowPassword(!showPassword);
 };
 const onKeyDownHandler = e => {if(e.key == "Enter"){handleSubmit()}}



    return (
        <div onKeyDown={onKeyDownHandler}>
            <div className='h-[100vh] bg-[#FFFCEF] relative flex items-center justify-center max-sm:bg-[#ffff]'>
                <div className='absolute  w-[505px] h-[499.5px] rounded-2xl z-20 bg-[#ffffff] max-sm:w-[375px]  grid grid-rows-[140.5px,1fr] p-[32px] gap-y-[16px] max-sm:mt-[-170px] '>
                    <div className=' max-sm:w-[310px]' >
                    <div className='  flex  justify-center' >
                        <img className='w-[118.5px] h-[67.5px] max-sm:w-[100px] max-sm:object-contain' src={Logo} alt=''/>
                    </div>
                    <div className='h-[57px] mt-[8px]'>
                        <h4 className='text-center font-[700] text-[24px] text-[#32324D] max-sm:text-[20px]' style={{fontFamily:'Montserrat, sans-serif'}}>Welcome </h4>
                        <h6 className='text-center font-[500] text-[16px] text-[#666687]  max-sm:text-[12px]' style={{fontFamily:'Montserrat, sans-serif'}} >Log in to your Viventure account</h6>
                </div>

            </div>
            <div className='   max-sm:w-[310px]'>
                <form action className="form_main" >
                    <div className='h-[162px] w-[100%]  '>
                        <div className="m-0 " >
                            <label className="block text-[#797777]  font-[500] text-[12px]" style={{fontFamily:'Montserrat, sans-serif'}} htmlFor="Email">
                                Email
                            </label>
                            <input className="w-[440px] max-sm:w-[310px] h-[48px] rounded-[8px] bg-[#F8F8F9] pl-3" id="email" type="email"  value={getEmail} onChange={handleUsernameChange}/>
                        </div>
                        <div className="mt-[10px] relative">
                            <label className="block text-[#797777]  font-[500] text-[12px]" style={{fontFamily:'Montserrat, sans-serif'}} htmlFor="password">
                                Password
                            </label>
                            <input className="w-[440px] h-[48px] max-sm:w-[310px] relative rounded-[8px] bg-[#F8F8F9] pl-3  " id="password" type={showPassword?"text":"password"} value={getPassword} onChange={handlePasswordChange} />
                     
                            <div onClick={togglePasswordVisible} className='cursor-pointer'>
                            {showPassword?<img className='absolute w-[16px] right-4 bottom-4' src={Hide} alt=''/>
                            :
                            <img className='absolute w-[16px] right-4 bottom-[18px]' src={Eye} alt=''/>}
                                
                            </div>
                           
                        </div>
                    </div>
                    <div className='  mt-[16px]'>
                        <div className="flex items-center mb-[26px] ">
                            <input className='w-[20px] h-[20px] border-[#C0C0CF] rounded' type="checkbox" id="Remember_me" name="Remember_me"  />
                            <label  htmlFor="Remember_me">
                                <h6 className='font-[400] text-[14px] leading-[20px] text-[#32324D] pl-2 pt-2'>Remember me</h6>
                            </label>
                        </div>
                        {getInputError && <div style={{color: 'red', fontSize: '12px'}}>{getInputErrorMessage}</div>}
                    </div>
                </form>
                <button className='w-[440px] max-sm:w-[310px] h-[41px] bg-[#F9B916] hover:bg-[#e7c160] rounded-[4px] font-[600] text-[14px] text-[#7A6161]' 
                    onClick={() => handleSubmit()}>
                    Login
                </button>
            </div>
        </div>
    {screenWidth < 850 ? <div className='flex items-end justify-center'> <img className="w-[210px] object-cover absolute  bottom-0  max-md:w-[210px] max-sm:w-[250px]   " src={Img2} alt=''/> </div> : <div>  <img className="w-[140px] object-cover absolute left-0 top-[350px]  " src={Img1} alt=''/> <img className="w-[140px] object-cover absolute right-0 bottom-[100px]  " src={Img3} alt=''/> <div className='flex items-end justify-center'> <img className="w-[210px] max-md:w-[210px] object-cover absolute  bottom-0   " src={Img2} alt=''/> </div> </div> }
    </div>

    </div>
    )
}


export default Login
