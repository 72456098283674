import React from 'react'
import Navbar from '../Components/Navbar';
import Hand from '../Assets/images/hand.png';
import { useState,useEffect } from 'react';
import Frame from '../Assets/images/Frame.png';
import Frame2 from '../Assets/images/Frame2.png';
import Frame3 from '../Assets/images/Calendar.png';
import Frame4 from '../Assets/images/Invoice1.png';
import Calendar_svg from '../Assets/images/Calendar_svg.svg';
import { Modal } from 'antd';
import '../Assets/css/App.css';
import axios from 'axios';
import '../Styles/Admin_panel.css';
import Pdf2 from '../Assets/images/pdf2.png'
import Excel2 from '../Assets/images/Excel2.webp'

import { format } from 'date-fns';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import {download, generateCsv, mkConfig} from "export-to-csv";
import datetime from 'datetime';


import {MakeNetworkCall} from '../Commons/MakeNetworkCall';
import {getAdminHomeUrls,postSalesDeletesUrl,postSalesDetailsUrl,postSalesUrl,postStateHeadFilterSaleUrls,postEditSalesUrl,getRepoByLocationID} from '../Commons/Urls'
import { MaterialSymbol } from 'react-material-symbols';

import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';

import { saveAs } from 'file-saver';
import ErrorModel from '../Components/ErrorModel';

// paginatiom
// import Pagination from '@mui/material/Pagination';
import Pagination from '../Components/Pagination';
import Stack from '@mui/material/Stack';

import moment from 'moment';
import {Link} from 'react-router-dom'


const LOG_TAG = "StateHead";
function StateHead() {
  const currentDate = new Date();
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [getPageLoadCount,setPageLoadCount ] = useState(0);
  const [loader ,setLoader] =useState(false);
  const [OutstandingLoader ,setOutstandingLoader] =useState(false);
  currentDate.setDate(1);
  const [getSDate, setSDate] = useState(currentDate.toISOString().split('T')[0]);
  console.log(getSDate);

  const lastDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1);
  const [getEDate, setEDate] = useState(lastDayOfMonth.toISOString().split('T')[0]);

  useEffect(() => {
    setLoader(true);
      FOnPageLoad();
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [getSDate, getEDate]);
const [visible,setVisible ] = useState(false);
const [showCalender,setShowCalender ] = useState(false);
const [startDate, setStartDate] = useState(null);
const [endDate, setEndDate] = useState(null);
const [showModal, setShowModal] = useState(false);
const [getDelete,setDelete ] = useState(false);
const [getDeleteItemId,setDeleteItemId ] = useState(false);
const [getSelectInvoiceNo,setSelectInvoiceNo ] = useState(false);
//edit table
const [edit,setEdit ] = useState(false);
//table button  --All list and outstanding--
const [activeButton, setActiveButton] = useState('btn1');
//upload file
const [getFile, setFile] = useState([]);
const [fileName, setFileName] = useState('');


const [getEditFile, setEditFile] = useState([]);
const [editFileName, setEditFileName] = useState('');

const [getAuth, setAuth] = useState([]);
const [getOutStanding, setOutStanding] = useState([]);
const [getOverdue, setOverdue] = useState([]);
const [getAllData, setAllData] = useState([]);
const [getRepo, setRepo] = useState([]);
const [getDistricts, setDistricts] = useState([]);
const [getPayment, setPayment] = useState([]);
const [getEditModelData,setEditModelData ] = useState();

const [getSelectedDistrict,setSelectedDistrict ] = useState("");
const [getSelectedClientName,setSelectedClientName ] = useState("");
const [getSelectedRepresentativeName,setSelectedRepresentativeName ] = useState("");
const [getSelectedInvoiceNo,setSelectedInvoiceNo ] = useState("");
const [getSelectedInvoiceDate,setSelectedInvoiceDate ] = useState("");
const [getSelectedPTRAmount,setSelectedPTRAmount ] = useState("0");
const [getSelectedTradeDiscount,setSelectedTradeDiscount ] = useState("0");
const [getSelectedGSTPlusCess,setSelectedGSTPlusCess ] = useState("0");
const [getSelectedTotal,setSelectedTotal ] = useState("0");
const [getSelectedBillStatus,setSelectedBillStatus ] = useState(0);
const [getSelectedImageId,setSelectedImageId ] = useState(null);

const [getEditSelectedDistrict,setEditSelectedDistrict ] = useState();
const [getEditSelectedClientName,setEditSelectedClientName ] = useState();
const [getEditSelectedRepresentativeName,setEditSelectedRepresentativeName ] = useState();
const [getEditSelectedInvoiceNo,setEditSelectedInvoiceNo ] = useState();
const [getEditSelectedInvoiceDate,setEditSelectedInvoiceDate ] = useState();
const [getEditSelectedPTRAmount,setEditSelectedPTRAmount ] = useState();
const [getEditSelectedTradeDiscount,setEditSelectedTradeDiscount ] = useState();
const [getEditSelectedGSTPlusCess,setEditSelectedGSTPlusCess ] = useState();
const [getEditSelectedTotal,setEditSelectedTotal ] = useState();
const [getEditSelectedBillStatus,setEditSelectedBillStatus ] = useState();
const [getEditSelectedImageId,setEditSelectedImageId ] = useState();


const [getClosedDealCount,setClosedDealCount ] = useState('0');
const [getClosedAmount,setClosedAmount ] = useState('0');
const [getOutStandingAmount,setOutStandingAmount ] = useState('0');
const [getOutStandingCount,setOutStandingCount ] = useState('0');
const [getOverDueAmount,setOverDueAmount ] = useState('0');
const [getOverDueCount,setOverDueCount ] = useState('0');

const [getSelectedRepoSort,setSelectedRepoSort ] = useState('0');
const [getSelectedDistrictSort,setSelectedDistrictSort ] = useState(null);
const [getSelectedItemId,setSelectedItemId ] = useState(null);



const [getEditSubmitError,setEditSubmitError ] = useState(false);
const [getSubmitError,setSubmitError ] = useState(null);
const [getSubmitErrorMessage,setSubmitErrorMessage ] = useState(false);
const [getEditSubmitErrorMessage,setEditSubmitErrorMessage ] = useState(null);


const [getErrorModelVisible,setErrorModelVisible ] = useState(false);
const [getErrorModelMessage,setErrorModelMessage ] = useState("");

const [currentPage, setCurrentPage] = useState(1);
const [recordsPerPage] = useState(10);

const indexOfLastRecord = currentPage * recordsPerPage;
const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
const currentRecords = getAllData.slice(indexOfFirstRecord, indexOfLastRecord);
const nPages = Math.ceil(getAllData.length / recordsPerPage)

  const pageDistrictCallback = (res) => {
    console.log(LOG_TAG,'=========pageFilterCallback-res:',res);
    const data = res.data;
    let keyOnly = Object.keys(data);
    setRepo(keyOnly.includes('repo') ? data.repo : []);
  }
const handleDistrictChange = (e) => { 
  setSelectedDistrict(e.target.value); 
  //Call Repos By DIstrict ID
  let obj = {
    distic_id:e.target.value
  }
  MakeNetworkCall(getRepoByLocationID,'POST',obj,pageDistrictCallback)
};
const handleClientNameChange = (e) => { setSelectedClientName(e.target.value); };
const handleRepresentativeNameChange = (e) => { setSelectedRepresentativeName(e.target.value); };
const handleInvoiceNoChange = (e) => { setSelectedInvoiceNo(e.target.value); };
const handleInvoiceDateChange = (e) => { setSelectedInvoiceDate(e.target.value); };
const handlePTRAmountChange = (e) => {  
  
  if(e.target.value){
    if(/\D/.test(e.target.value)){

    }else{
  console.log("========:",e.target.value); setSelectedPTRAmount(e.target.value);
 }
}

};

const handleTradeDiscountChange = (e) => { 
  
  if(e.target.value){
    if(/\D/.test(e.target.value)){

    }else{
  
  setSelectedTradeDiscount(e.target.value);
   }
 }
 };
const handleGSTPlusCessChange = (e) => { 
  if(e.target.value){
    if(/\D/.test(e.target.value)){

    }else{
      setSelectedGSTPlusCess(e.target.value);
    }

  }

};
const handleTotalChange = (e) => { 
  if(e.target.value){
    if(/\D/.test(e.target.value)){

    }else{
  setSelectedTotal(e.target.value); 
  }
}
};
const handleBillStatusChange = (e) => { setSelectedBillStatus(e.target.value); };

const handleEditDistrictChange = (e) => { setEditSelectedDistrict(e.target.value); };
const handleEditClientNameChange = (e) => { setEditSelectedClientName(e.target.value); };
const handleEditRepresentativeNameChange = (e) => { setEditSelectedRepresentativeName(e.target.value); };
const handleEditInvoiceNoChange = (e) => { setEditSelectedInvoiceNo(e.target.value); };
const handleEditInvoiceDateChange = (e) => { setEditSelectedInvoiceDate(e.target.value); };
const handleEditPTRAmountChange = (e) => { 
  if(e.target.value){
    if(/\D/.test(e.target.value)){

    }else{
      setEditSelectedPTRAmount(e.target.value);
   }
 }
 };
const handleEditTradeDiscountChange = (e) => { 
  if(e.target.value){
    if(/\D/.test(e.target.value)){

    }else{
      setEditSelectedTradeDiscount(e.target.value);
   }
 }
 };
const handleEditGSTPlusCessChange = (e) => { 
  if(e.target.value){
    if(/\D/.test(e.target.value)){

    }else{
      setEditSelectedGSTPlusCess(e.target.value); 
  }
}
};
const handleEditTotalChange = (e) => { 
  if(e.target.value){
    if(/\D/.test(e.target.value)){

    }else{
      setEditSelectedTotal(e.target.value); 
  }
}
};
const handleEditBillStatusChange = (e) => { setEditSelectedBillStatus(e.target.value); };


    const [getEditSelectedItemId,setEditSelectedItemId ] = useState(null);
    const [getSubmitDistrictError,setSubmitDistrictError ] = useState(false);
    const [getSubmitClientError,setSubmitClientError ] = useState(false);
    const [getSubmitRepoNameError,setSubmitRepoNameError ] = useState(false);
    const [getSubmitInvoiceNoError,setSubmitInvoiceNoError ] = useState(false);
    const [getSubmitInvoiceDateError,setSubmitInvoiceDateError ] = useState(false);
    const [getSubmitPTRAmountError,setSubmitPTRAmountError ] = useState(false);
    const [getSubmitTradeDiscountError,setSubmitTradeDiscountError ] = useState(false);
    const [getSubmitGSTCessError,setSubmitGSTCessError ] = useState(false);
    const [getSubmitTotalError,setSubmitTotalError ] = useState(false);
    const [getSubmitBillStatusError,setSubmitBillStatusError ] = useState(false);

    const [getSubmitDistrictErrorMessage,setSubmitDistrictErrorMessage ] = useState("");
    const [getSubmitClientErrorMessage,setSubmitClientErrorMessage ] = useState("");
    const [getSubmitRepoNameErrorMessage,setSubmitRepoNameErrorMessage ] = useState("");
    const [getSubmitInvoiceNoErrorMessage,setSubmitInvoiceNoErrorMessage ] = useState("");
    const [getSubmitInvoiceDateErrorMessage,setSubmitInvoiceDateErrorMessage ] = useState("");
    const [getSubmitPTRAmountErrorMessage,setSubmitPTRAmountErrorMessage ] = useState("");
    const [getSubmitTradeDiscountErrorMessage,setSubmitTradeDiscountErrorMessage ] = useState("");
    const [getSubmitGSTCessErrorMessage,setSubmitGSTCessErrorMessage ] = useState("");
    const [getSubmitTotalErrorMessage,setSubmitTotalErrorMessage ] = useState("");
    const [getSubmitBillStatusErrorMessage,setSubmitBillStatusErrorMessage ] = useState("");



    const [getEditDistrictError,setEditDistrictError ] = useState(false);
    const [getEditClientNameError,setEditClientNameError ] = useState(false);
    const [getEditRepresentativeError,setEditRepresentativeError ] = useState(false);
    const [getEditInvoiceNoError,setEditInvoiceNoError ] = useState(false);
    const [getEditInvoiceDateError,setEditInvoiceDateError ] = useState(false);
    const [getEditPTRAmountError,setEditPTRAmountError ] = useState(false);
    const [getEditTradeDiscountError,setEditTradeDiscountError ] = useState(false);
    const [getEditGSTCessError,setEditGSTCessError ] = useState(false);
    const [getEditTotalError,setEditTotalError ] = useState(false);
    const [getEditPaymentError,setEditPaymentError ] = useState(false);

    const [getEditDistrictErrorMessage,setEditDistrictErrorMessage ] = useState("");
    const [getEditClientNameErrorMessage,setEditClientNameErrorMessage ] = useState("");
    const [getEditRepresentativeErrorMessage,setEditRepresentativeErrorMessage ] = useState("");
    const [getEditInvoiceNoErrorMessage,setEditInvoiceNoErrorMessage ] = useState("");
    const [getEditInvoiceDateErrorMessage,setEditInvoiceDateErrorMessage ] = useState("");
    const [getEditPTRAmountErrorMessage,setEditPTRAmountErrorMessage ] = useState("");
    const [getEditTradeDiscountErrorMessage,setEditTradeDiscountErrorMessage ] = useState("");
    const [getEditGSTCessErrorMessage,setEditGSTCessErrorMessage ] = useState("");
    const [getEditTotalErrorMessage,setEditTotalErrorMessage ] = useState("");
    const [getEditPaymentErrorMessage,setEditPaymentErrorMessage ] = useState("");





  // const [sDate, setSelectedDate] = useState(currentDate.toISOString().split('T')[0]);
  // const [eDate, setSelectedendDate] = useState(lastDayOfMonth.toISOString().split('T')[0]);


  

    const [getSDateISO, setSDateISO] = useState(beginningOfMonth(new Date()));
    const [getEDateISO, setEDateISO] = useState(endOfMonth(new Date()));

    function beginningOfMonth(myDate){    
      let date = new Date(myDate);
      date.setDate(1)
      date.setHours(0);
      date.setMinutes(0);
      date.setSeconds(0);   
      return date;     
    }
    function endOfMonth(myDate){
      let date = new Date(myDate);
      date.setDate(1); // Avoids edge cases on the 31st day of some months
      date.setMonth(date.getMonth() +1);
      date.setDate(0);
      date.setHours(23);
      date.setMinutes(59);
      date.setSeconds(59);
      return date;
    }

  const [isLoading, setIsLoading] = useState(false);
  const [edit_Loading, setEdit_Loading] = useState(false);


  const handleRepoSortChange = (e) => { 
    setSelectedRepoSort(e.target.value);
    pageFilter({'rep':e.target.value == '0' ? null : e.target.value,'dist':getSelectedDistrictSort,'startd':getSDate,'endd':getEDate,'hint':activeButton == 'btn1' ? 'all':'outstand'});
  };
  const handleDistrictSortChange = (e) => {  
    console.log(e);
    if(e != null){
      setSelectedDistrictSort(e.target.value); 
      pageFilter({'dist':e.target.value,'rep':getSelectedRepoSort == '0' ? null : getSelectedRepoSort,'startd':getSDate,'endd':getEDate,'hint':activeButton == 'btn1' ? 'all':'outstand'});
    }else{
      pageFilter({'dist':null,'rep':getSelectedRepoSort == '0' ? null : getSelectedRepoSort,'startd':getSDate,'endd':getEDate,'hint':activeButton == 'btn1' ? 'all':'outstand'});
    }
  };

function clearAllFields() {
  setSelectedDistrict(0);
  setSelectedClientName("");
  setSelectedRepresentativeName(0);
  setSelectedInvoiceNo("");
  setSelectedInvoiceDate("");
  setSelectedPTRAmount("");
  setSelectedTradeDiscount("");
  setSelectedGSTPlusCess("");
  setSelectedTotal("");
  setSelectedBillStatus(0);
  setFile([]);
  setFileName("");
  setVisible(false);
  FOnPageLoad();
  setSubmitError("");
  setEditSubmitError("");
}


const handleSelectDate = () => { 
  setShowModal(true); 
};

const handleModalClose = () => {
   setShowModal(false);
   setShowCalender(false);
};

const handleDateSelect = (date) => {
  console.log('=======date:',date);
  if (!startDate) {
    setStartDate(date);
  } else if (!endDate) {
    setEndDate(date);
  } else {
    setStartDate(date);
    setEndDate(null);
  }
};

const handleOkButtonClick = () => {
  setShowCalender(false);
  setShowModal(false);
  // Perform any other actions with selected dates
};

const formatSelectedDates = () => {
  if (startDate && endDate) {
    return `${startDate.toLocaleDateString()} - ${endDate.toLocaleDateString()}`;
  } else if (startDate) {
    return startDate.toLocaleDateString();
  } else {
    return 'Select Date';
  }
};

function generateCSVFile() {
  if(getAllData.length != 0){
      console.log("=====calls:",getAllData);
      console.log("=====activeButton:",activeButton);
      if(activeButton == 'btn1'){
        const csvConfig = mkConfig({
          useKeysAsHeaders: true,
          filename:"Export " + new Date(),
          showTitle:true,
          title:"Generated Call Report",
        });
        let arra = new Array();
        for (let i = 0; i < getAllData.length; i++) {
            let obj = {
                "#":i+1,
                "Client Name":getAllData[i].client_name,
                "Rep Name":getAllData[i].repo_name,
                "Invoice No":getAllData[i].invoice_no,
                "Invoice Date":getAllData[i].invoice_date,
                "PTR amount":getAllData[i].ptr_amount,
                "Trade Disc":getAllData[i].trade_dis,
                "GST + Cess":getAllData[i].gstpluscess ,
                "Age":getAllData[i].payment_status === "Unpaid" ? getAllData[i].age : '',
                "Payment":getAllData[i].payment_status ,
                "Total":getAllData[i].total,
            }
            arra.push(obj);
        }
        console.log("=====:",arra);

        const csv = generateCsv(csvConfig)(arra);
        download(csvConfig)(csv);
      }else{
        if(getOutStanding.length != 0){
          const csvConfig = mkConfig({
            useKeysAsHeaders: true,
            filename:"Export " + new Date(),
            showTitle:true,
            title:"Generated Call Report",
          });
          let arra = new Array();
          for (let i = 0; i < getOutStanding.length; i++) {
              let obj = {
                  "#":i+1,
                  "Client Name":getOutStanding[i].client_name,
                  "Rep Name":getOutStanding[i].repo_name,
                  "Invoice No":getOutStanding[i].invoice_no,
                  "Invoice Date":getOutStanding[i].invoice_date,
                  "PTR amount":getOutStanding[i].ptr_amount,
                  "Trade Disc":getOutStanding[i].trade_dis,
                  "GST + Cess":getOutStanding[i].gstpluscess ,
                  "Age":getOutStanding[i].age ,
                  "Payment":getOutStanding[i].payment_status ,
                  "Total":getOutStanding[i].total,
              }
              arra.push(obj);
          }
          console.log("=====:",arra);
  
          const csv = generateCsv(csvConfig)(arra);
          download(csvConfig)(csv);
        }if(getOverdue.length != 0){
          const csvConfig = mkConfig({
            useKeysAsHeaders: true,
            filename:"Export " + new Date(),
            showTitle:true,
            title:"Generated Call Report",
          });
          let arra = new Array();
          for (let i = 0; i < getOverdue.length; i++) {
              let obj = {
                  "#":i+1,
                  "Client Name":getOverdue[i].client_name,
                  "Rep Name":getOverdue[i].repo_name,
                  "Invoice No":getOverdue[i].invoice_no,
                  "Invoice Date":getOverdue[i].invoice_date,
                  "PTR amount":getOverdue[i].ptr_amount,
                  "Trade Disc":getOverdue[i].trade_dis,
                  "GST + Cess":getOverdue[i].gstpluscess ,
                  "Age":getOverdue[i].age ,
                  "Payment":getOverdue[i].payment_status ,
                  "Total":getOverdue[i].total,
              }
              arra.push(obj);
          }
          console.log("=====:",arra);
  
          const csv = generateCsv(csvConfig)(arra);
          download(csvConfig)(csv);
        }
      }
  }else{
      alert("No Data available..!");
  }
}

const handleButtonClick = (button) => {
  setActiveButton(null);
  setActiveButton(button);
  setSelectedRepoSort(null);
  handleDistrictSortChange(null);
  if(button == 'btn1'){
    pageFilter({'rep':getSelectedRepoSort == '0' ? null : getSelectedRepoSort,'dist':getSelectedDistrictSort,'startd':getSDate,'endd':getEDate,'hint': 'all'});
  }else{
    pageFilter({'rep':getSelectedRepoSort == '0' ? null : getSelectedRepoSort,'dist':getSelectedDistrictSort,'startd':getSDate,'endd':getSDate,'hint':'outstand'});
  }
};

const pageFilterCallback = (res) => {
  console.log(LOG_TAG,'=========pageFilterCallback-res:',res);
  setLoader(false);
  setOutstandingLoader(false);
    if(res.status){
      const data = res.data;
      let keyOnly = Object.keys(data);
      setAuth(keyOnly.includes('auth') ? data.auth : []);
      setDistricts(keyOnly.includes('districts') ? data.districts : []);
      setPayment(keyOnly.includes('payment') ? data.payment : []);
      setRepo(keyOnly.includes('repo') ? data.repo : []);
      setOutStanding(keyOnly.includes('outstanding') ? data.outstanding : []);
      setOverdue(keyOnly.includes('overdue') ? data.overdue : []);
      setAllData(keyOnly.includes('all') ? data.all : []);
      setClosedDealCount(keyOnly.includes('closeddealscount') ? data.closeddealscount : "0");
      setClosedAmount(keyOnly.includes('closedamount') ? data.closedamount : "0");
      setOutStandingAmount(keyOnly.includes('outstandingamount') ? data.outstandingamount : "0");
      setOutStandingCount(keyOnly.includes('outstandingcount') ? data.outstandingcount : "0");
      setOverDueAmount(keyOnly.includes('overdueamount') ? data.overdueamount : "0");
      setOverDueCount(keyOnly.includes('overduecount') ? data.overduecount : "0");

  setSubmitError("");
  setEditSubmitError("");
  }else{
      console.log('=========callback:',res);
      setSubmitError("");
      setEditSubmitError("");
  }
}

const pageFilter  = (obj) => {
  console.log(LOG_TAG,'=========obj:',obj);
  console.log(LOG_TAG,'=========postFilterSaleUrls:',postStateHeadFilterSaleUrls);
  MakeNetworkCall(postStateHeadFilterSaleUrls,'POST',obj,pageFilterCallback)
}

const handleFileChange = (e) => {
  const file = e.target.files[0];
  console.log('=========file:1:',file);
  
  if (file) {
    if(file.size > 1900000){
      setSubmitError(true);
      setSubmitErrorMessage("Selected Image Graterthan allowed size....!");
    }else{
      console.log('=========handleFileChange:1:');
      setSubmitError(false);
      setFile(e.target.files);
      setFileName(file.name);
    }
  } else {
    console.log('=========handleFileChange:2:');
    setFile([]);
    setFileName('');
    setSubmitError("");
    setEditSubmitError("");
  }
};

const handleEditFileChange = (e) => {
  const file = e.target.files[0];
  if (file) {
    if(file.size > 1900000){
      setEditSubmitError(true);
      setEditSubmitErrorMessage("Selected Image Graterthan allowed size....!");
    }else{
      console.log('=========handleFileChange:1:');
      setEditSubmitError(false);
      setEditFile(e.target.files);
      setEditFileName(file.name);
    }
  } else {
    console.log('=========handleFileChange:2:');
    setEditFile([]);
    setEditFileName('');
    setSubmitError("");
    setEditSubmitError("");
  }
};

const pageOnLoadCallBack = (res) => {
  console.log('=========pageOnLoadCallBack-callback-status:',res.status);
  setPageLoadCount(getPageLoadCount + 1);
  if(res.status){
      const data = res.data;
      let keyOnly = Object.keys(data);
      setAuth(keyOnly.includes('auth') ? data.auth : []);
      setDistricts(keyOnly.includes('districts') ? data.districts : []);
      setPayment(keyOnly.includes('payment') ? data.payment : []);
      setRepo(keyOnly.includes('repo') ? data.repo : []);
      setOutStanding(keyOnly.includes('outstanding') ? data.outstanding : []);
      setOverdue(keyOnly.includes('overdue') ? data.overdue : []);
      setAllData(keyOnly.includes('all') ? data.all : []);
      setClosedDealCount(data.all.length);
      setClosedAmount(keyOnly.includes('closedamount') ? data.closedamount : "0");
      setOutStandingAmount(keyOnly.includes('outstandingamount') ? data.outstandingamount : "0");
      setOutStandingCount(keyOnly.includes('outstandingcount') ? data.outstandingcount : "0");
      setOverDueAmount(keyOnly.includes('overdueamount') ? data.overdueamount : "0");
      setOverDueCount(keyOnly.includes('overduecount') ? data.overduecount : "0");
      setErrorModelVisible(false);


  setSubmitError("");
  setEditSubmitError("");
  }else{
      console.log('=========callback:',res);
      //setErrorModelVisible(true);
      //setErrorModelMessage("Error Occured While Loading Data, Logout Once and Login again..!");
  setSubmitError("");
  setEditSubmitError("");
  }
}
//onPageLoad function
const FOnPageLoad = () => {
    // MakeNetworkCall(getAdminHomeUrls,'GET',{},pageOnLoadCallBack);
    pageFilter({'rep':getSelectedRepoSort == '0' ? null : getSelectedRepoSort,'dist':getSelectedDistrictSort,'startd':getSDate,'endd':getEDate,'hint':activeButton == 'btn1' ? 'all':'outstand'});
}


async function setEditItem(item){
  setEdit_Loading(false);
  FClearEditModelCancelBtn();
  console.log("======Item:",item);
  const obj = {item_id: item.id};
  console.log("======obj:",obj);
  console.log("======postSalesDetailsUrl:",postSalesDetailsUrl);
  const response = await fetch(postSalesDetailsUrl, {
      method: 'POST',
      headers: {'Content-Type': 'application/json', 'Authorization': "Bearer " + sessionStorage.getItem('TOKEN')},
      body: JSON.stringify(obj)
  });
  if (!response.status) {
      throw new Error('Network response was not ok');
  } else {
      const responseData = await response.json();
      console.log('=========DATA:', responseData.data.item[0]);
      setEditModelData(responseData.data.item[0]);
      console.log('=========getEditModelData:', getEditModelData);
      setEditSelectedItemId(item.id);

      setEditSelectedDistrict(responseData.data.item[0].district_id);
      setEditSelectedClientName(responseData.data.item[0].client_name);
      setEditSelectedRepresentativeName(responseData.data.item[0].rep_id);
      setEditSelectedInvoiceNo(responseData.data.item[0].invoice_no);
      setEditSelectedInvoiceDate(responseData.data.item[0].invoice_date);
      setEditSelectedPTRAmount(responseData.data.item[0].ptr_amount);
      setEditSelectedTradeDiscount(responseData.data.item[0].trade_dis);
      setEditSelectedGSTPlusCess(responseData.data.item[0].gstpluscess);
      setEditSelectedTotal(responseData.data.item[0].total);
      setEditSelectedBillStatus(responseData.data.item[0].payment_id);
      setEditSelectedImageId(responseData.data.item[0].media_id);
      setEdit(true);

  setSubmitError("");
  setEditSubmitError("");
  }

  
}

async function setDeleteItem(item){
  console.log('=======Item:',item);
  setSelectInvoiceNo(item.invoice_no);
  setDeleteItemId(item.id);
  setDelete(true);
}

async function submitDeleteItem(){
  const obj = {item_id: getDeleteItemId};
  console.log("======obj:",obj);
  console.log("======postSalesDetailsUrl:",postSalesDeletesUrl);
  const response = await fetch(postSalesDeletesUrl, {
      method: 'POST',
      headers: {'Content-Type': 'application/json', 'Authorization': "Bearer " + sessionStorage.getItem('TOKEN')},
      body: JSON.stringify(obj)
  });
  if (!response.status) {
      throw new Error('Network response was not ok');
  } else {
      const responseData = await response.json();
      console.log('=========resp:', responseData.status);
      if(responseData.status){
        setDelete(false);
          FOnPageLoad();
      }else{
          console.log('=========resp:', responseData);
      }
  }
} 

function handleSubmit(){
  // console.log('=========resp:', getSelectedPTRAmount.length);
  // console.log('=========resp:', getSelectedTradeDiscount.length);
  // console.log('=========resp:', getSelectedGSTPlusCess.length);
  // console.log('=========resp:', getSelectedTotal.length);
  // console.log('=========resp:', getSelectedBillStatus.length);
      if(getSelectedDistrict != 0){
        if(getSelectedClientName.length != 0){
          if(getSelectedRepresentativeName.length != 0){
            if(getSelectedInvoiceNo.length != 0){
              if(getSelectedInvoiceDate.length != 0){
                if(getSelectedPTRAmount.length != "0"){
                  if(getSelectedTradeDiscount.length != "0"){
                    if(getSelectedGSTPlusCess.length != "0"){
                      if(getSelectedTotal.length != "0"){
                        if(getSelectedBillStatus != 0){
                          setSubmitBillStatusError(false);
                          setSubmitTotalError(false); 
                          setSubmitGSTCessError(false);
                          setSubmitTradeDiscountError(false);
                          setSubmitPTRAmountError(false);
                          setSubmitInvoiceNoError(false);
                          setSubmitInvoiceDateError(false);
                          setSubmitRepoNameError(false);
                          setSubmitClientError(false);
                          setSubmitDistrictError(false);

                          setIsLoading(false);  
                          let data = new FormData();
                          data.append("location_id",getSelectedDistrict);
                          data.append("client_name",getSelectedClientName);
                          data.append("rep_name",getSelectedRepresentativeName);
                          data.append("invoice_no",getSelectedInvoiceNo);
                          data.append("invoice_date",getSelectedInvoiceDate);
                          data.append("ptr_amount",getSelectedPTRAmount);
                          data.append("trade_discount",getSelectedTradeDiscount);
                          data.append("gstpluscess",getSelectedGSTPlusCess);
                          data.append("total",getSelectedTotal);
                          data.append("payment_id",getSelectedBillStatus);
                          console.log(LOG_TAG,"=========getFile:",getFile);
                          if(getFile.length > 0){
                              console.log(LOG_TAG,"=========getSelectedImage:",getFile[0]);
                              data.append("file", getFile[0],getFile[0].name);
                          }
                  
                          let config = {
                              method: 'post',
                              maxBodyLength: Infinity,
                              url: postSalesUrl,
                              headers: { 
                                  'Content-type': 'multipart/form-data',
                                  'Authorization': `Bearer ${sessionStorage.getItem('TOKEN')}`, 
                              },
                              data : data
                          };
                  
                          axios.request(config)
                          .then((response) => {
                              console.log(JSON.stringify(response.data));
                              let resp = response.data;
                              console.log(LOG_TAG,"========status:",resp.status);
                              setIsLoading(false);  
                              if(resp.status){
                                  clearAllFields();
                                  FOnPageLoad();
                              }else{
                                setErrorModelVisible(true);
                                setErrorModelMessage("Form Submit Error Occued..!");
                              }
                          })
                          .catch((error) => {
                            setIsLoading(false);  
                          console.log(error);
                          });
                        }else{
                          setSubmitBillStatusError(true);
                          setSubmitBillStatusErrorMessage('Select Bill Status')
                        }
                      }else{
                         setSubmitTotalError(true); 
                         setSubmitTotalErrorMessage("Enter Total Amount");
                      }
                    }else{
                         setSubmitGSTCessError(true);
                         setSubmitGSTCessErrorMessage("Enter GST Cess Amunt"); 
                    }
                  }else{
                       setSubmitTradeDiscountError(true);   
                       setSubmitTradeDiscountErrorMessage('Enter Trade Discount Amount')
                  }
                }else{
                    setSubmitPTRAmountError(true);
                    setSubmitPTRAmountErrorMessage("Enter PTR Amount");      
                }
              }else{
                  setSubmitInvoiceDateError(true);
                  setSubmitInvoiceDateErrorMessage("Enter Invoice Data");        
              }
            }else{
               setSubmitInvoiceNoError(true);
               setSubmitInvoiceNoErrorMessage("Enter Invoice Number")           
            }
          }else{
               setSubmitRepoNameError(true);
               setSubmitRepoNameErrorMessage('Select Representative')           
          }
        }else{
            setSubmitClientError(true);
            setSubmitClientErrorMessage("Enter Client Name");               
        }
      }else{
         setSubmitDistrictError(true);
         setSubmitDistrictErrorMessage("Select District");                  
      }
};

function clearEditForm() {
  console.log("========clearEditForm:");
  setSelectedItemId("");
  setSelectedDistrict("");
  setSelectedClientName("");
  setSelectedRepresentativeName("");
  setSelectedInvoiceNo("");
  setSelectedInvoiceDate("");
  setSelectedPTRAmount("");
  setSelectedTradeDiscount("");
  setSelectedGSTPlusCess("");
  setSelectedTotal("");
  setSelectedBillStatus("");
  setEdit(false);
  FOnPageLoad();
}

const downloadImage = (url,name) => {
  saveAs(url, name) // Put your image URL here.
}

function handleEditSubmit(){
  if(getEditSubmitError == false){
  try {

        if(getEditSelectedDistrict != 0){
          if(getEditSelectedClientName.length != 0){
            if(getEditSelectedRepresentativeName.length != 0){
              if(getEditSelectedInvoiceNo.length != 0){
                if(getEditSelectedInvoiceDate.length != 0){
                  if(getEditSelectedPTRAmount.length != "0"){
                    if(getEditSelectedTradeDiscount.length != "0"){
                      if(getEditSelectedGSTPlusCess.length != "0"){
                        if(getEditSelectedTotal.length != "0"){
                          if(getEditSelectedBillStatus.length != 0){
                            setEditDistrictError(false);
                            setEditClientNameError(false);
                            setEditRepresentativeError(false);
                            setEditInvoiceNoError(false);
                            setEditInvoiceDateError(false);
                            setEditPTRAmountError(false);
                            setEditTradeDiscountError(false);
                            setEditGSTCessError(false);
                            setEditTotalError(false);
                            setEditPaymentError(false);

                            setEdit_Loading(false);  
                            let data = new FormData();
                            data.append("item_id",getEditSelectedItemId);
                            data.append("location_id",getEditSelectedDistrict);
                            data.append("client_name",getEditSelectedClientName);
                            data.append("rep_name",getEditSelectedRepresentativeName);
                            data.append("invoice_no",getEditSelectedInvoiceNo);
                            data.append("invoice_date",getEditSelectedInvoiceDate);
                            data.append("ptr_amount",getEditSelectedPTRAmount);
                            data.append("trade_discount",getEditSelectedTradeDiscount);
                            data.append("gstpluscess",getEditSelectedGSTPlusCess);
                            data.append("total",getEditSelectedTotal);
                            data.append("payment_id",getEditSelectedBillStatus);
                            console.log(LOG_TAG,"=========getEditFile:",getEditFile);
                            if(getEditFile.length > 0){
                                console.log(LOG_TAG,"=========getSelectedImage:",getEditFile[0]);
                                data.append("file", getEditFile[0],getEditFile[0].name);
                            }else{
                              data.append("image",getEditSelectedImageId);
                            }
                            console.log(LOG_TAG,"=========data:",data);

                            let config = {
                                method: 'post',
                                maxBodyLength: Infinity,
                                url: postEditSalesUrl,
                                headers: { 
                                    'Content-type': 'multipart/form-data',
                                    'Authorization': `Bearer ${sessionStorage.getItem('TOKEN')}`, 
                                },
                                data : data
                            };

                            axios.request(config)
                            .then((response) => {
                                console.log(JSON.stringify(response.data));
                                let resp = response.data;
                                console.log(LOG_TAG,"========status:",resp.status);
                                setEdit_Loading(false);  
                                if(resp.status){
                                  setErrorModelVisible(false);
                                  setEdit(false);
                                  clearAllFields();
                                  FOnPageLoad();
                                }else{
                                  setErrorModelVisible(true);
                                  setErrorModelMessage("Edit Form Submit Error Occued..!");
                                }
                            })
                            .catch((error) => {
                              setEdit_Loading(false);  
                            console.log(error);
                            });
                          }else{
                            setEditPaymentError(true);
                            setEditPaymentErrorMessage("Choose Payment mode");
                          }
                        }else{
                          setEditTotalError(true);
                          setEditTotalErrorMessage("Enter Total Amount")
                          }
                      }else{
                        setEditGSTCessError(true);
                        setEditGSTCessErrorMessage("Enter GST Cess Amount");
                      }
                    }else{
                      setEditTradeDiscountError(true);
                      setEditTradeDiscountErrorMessage("Enter Trade Discount Amount");
                    }
                  }else{
                    setEditPTRAmountError(true);
                    setEditPTRAmountErrorMessage("Enter PTR Amount");
                  }
                }else{
                  setEditInvoiceDateError(true);
                  setEditInvoiceDateErrorMessage("Enter Invoice Date");    
                }
              }else{
                setEditInvoiceNoError(true);
                setEditInvoiceNoErrorMessage("Select Invoice Number")         
              }
            }else{
              setEditRepresentativeError(true);
              setEditRepresentativeErrorMessage("Select Representative")        
            }
          }else{
            setEditClientNameError(true);
            setEditClientNameErrorMessage("Enter Client Name");         
          }
        }else{
          getEditDistrictError(true);
          getEditDistrictErrorMessage('Select District');          
        }
    } catch (error) {
        // Handle errors, such as displaying an error message
        console.log('There was a problem with the API call:', error.toString());
    }
  }
};



const appendTableData = () => {
  const renderedItems = [];
      console.log("=======getData:",currentRecords);
      if(currentRecords.length != 0){
          //console.log("=======+1:"+1:":",getAllData.length);
          for (let i = 0; i < currentRecords.length; i++) {
              const item = currentRecords[i];
              renderedItems.push(
                <tbody className='h-[70px] font-[500] text-[16px] leading-[19.36px] text-[#464646] max-sm:text-[12px]'  style={{fontFamily:'Montserrat, sans-serif'}}>
                <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                <th scope="row" className="px-6 py-4 font-medium   w-[173px]  ">
                {item.client_name}
        
                 </th>
                 <td className="px-6 py-4 text-[#050505] w-[163px] ">
                   {item.repo_name}
                 </td>
                  <td className="px-6 py-4  text-[#050505] ">
                    #{item.invoice_no}
                  </td>
                  <td className="px-6 py-4  text-[#3C3C3C]">
                  {item.invoice_date}
                  </td>
                  <td className="px-6 py-4  text-[#3C3C3C]">
                  &#8377; {item.ptr_amount}
                  </td>
                  <td className="px-6 py-4  text-[#3C3C3C]">
                  {item.trade_dis}
                  </td>
                  <td className="px-6 py-4  text-[#3C3C3C]">
                  &#8377; {item.gstpluscess}
                  </td>
                  <td className="px-6 py-4  text-[#3C3C3C] font-[600]">
                  &#8377; {item.total}
                  </td>
                  {item.payment_id == 1 ?
                  <td className="pl-8 pr-5 py-4  text-[#3C3C3C] ">
                  <div className='  w-[65px] bg-[#DCFFC1] px-[18px]  pt-[5px] pb-[1px] rounded-[43px]'>
                    <h5 className='text-center font-[600] text-[14px] text-[#01570F] max-sm:text-[12px] mt-1'>Paid</h5>
                  </div>
                </td>: 
                <td className="px-3 py-4 ] ">
                <div className='w-[95px] bg-[#FFE8E8] px-[16px]  pt-[5px] pb-[1px] rounded-[43px] max-sm:w-[85px]'>
                  <h5 className='text-center font-[600] text-[14px] text-[#A8422B] max-sm:text-[12px]'>Unpaid</h5>
                </div>
              </td>
                  }
                  
                  
          
                  <td className="px-6 py-4  ">
                  <div className=' w-[95px] flex justify-between max-sm:w-[85px] max-sm:ml-[5px] '>
                      {/* <span className="material-symbols-outlined font-[300]  cursor-pointer max-sm:text-[22px] no-underline text-[#1d1d1d] "  type="button" onClick={() => {setEditItem(item)}} > border_color </span> */}
                      {/* <span className="material-symbols-outlined font-[300]  cursor-pointer max-sm:text-[22px] no-underline text-[#1d1d1d] " type="button" onClick={() => {setDeleteItem(item)}}> delete</span> */}
                      { item.media_id && item.media_id != '' && item.media_id != null ?  <button onClick={() => {downloadImage("https://prod.viventure.inaipi.ae/api/"+item.media_path,item.media_name)}}> <span className="material-symbols-outlined font-[300]  cursor-pointer max-sm:text-[22px]  no-underline text-[black]" type="button">download</span> </button> :  <span className="material-symbols-outlined font-[300]  cursor-pointer max-sm:text-[22px]  no-underline text-[#8b8b8b] ">file_download_off</span>}
                      </div>
                  </td>
          
                </tr>
                  </tbody>
              )
          }
      }else{
          renderedItems.push(<tbody className='h-[70px] font-[500] text-[16px] leading-[19.36px] text-[#464646]'>
              <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                  <th scope="row" className="px-6 py-4 font-medium  whitespace-nowrap ">
                  No Data Availabile
                  </th>
              </tr>
            
          </tbody>)
      }

      return renderedItems;
}

const appendOutStandingableData = () => {
  const renderedItems = [];
      console.log("=======appendOutStandingableData:getData:",getOutStanding);
      if(getOutStanding.length != 0){
          //console.log("=======+1:",getOutStanding.length);
          for (let i = 0; i < getOutStanding.length; i++) {
              const item = getOutStanding[i];
              renderedItems.push(
                <tbody className='h-[70px] font-[500] text-[16px] leading-[19.36px] text-[#464646] max-sm:text-[12px]'  style={{fontFamily:'Montserrat, sans-serif'}}>
                  <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 ">
                  <th scope="row" className="px-6 py-4 font-medium   w-[173px]  ">
                  {item.client_name}
          
                  </th>
                  <td className="px-6 py-4 text-[#050505] w-[163px] ">
                  {item.repo_name}
                  </td>
                    <td className="px-6 py-4  text-[#050505]">
                    #{item.invoice_no}
                    </td>
                    <td className="px-6 py-4  text-[#3C3C3C]">
                    {item.invoice_date}
                    </td>
                    <td className="px-6 py-4  text-[#3C3C3C]">
                    &#8377; {item.ptr_amount}
                    </td>
                    <td className="px-6 py-4  text-[#3C3C3C]">
                    {item.trade_dis}
                    </td>
                    <td className="px-6 py-4  text-[#3C3C3C]">
                    &#8377; {item.gstpluscess}
                    </td>
                    <td className="px-6 py-4  text-[#3C3C3C] font-[600]">
                    &#8377; {item.total}
                    </td>
                    <td className="px-6 py-4  text-[#3C3C3C]">
                   {item.age}
                    </td>
            
                    <td className="px-6 py-4  ">
                    <div className=' w-[95px] flex justify-between max-sm:w-[85px] max-sm:ml-[5px] '>
                    {/* <span className="material-symbols-outlined font-[300]  cursor-pointer max-sm:text-[22px] no-underline text-[#1d1d1d] "  type="button" onClick={() => {setEditItem(item)}} > border_color </span> */}
                    {/* <span className="material-symbols-outlined font-[300]  cursor-pointer max-sm:text-[22px] no-underline text-[#1d1d1d] " type="button" onClick={() => {setDeleteItem(item)}}> delete</span> */}
                    { item.media_id && item.media_id != '' && item.media_id != null ?  <button onClick={() => {downloadImage("https://prod.viventure.inaipi.ae/api/"+item.media_path,item.media_name)}}> <span className="material-symbols-outlined font-[300]  cursor-pointer max-sm:text-[22px]  no-underline text-[black]" type="button">download</span> </button> :  <span className="material-symbols-outlined font-[300]  cursor-pointer max-sm:text-[22px]  no-underline text-[#8b8b8b] ">file_download_off</span>}
                        </div>
                    </td>
            
                  </tr>
                  </tbody>
              )
          }
      }else if(getOverdue.length != 0){
        //console.log("=======+1:",getOverdue.length);
          for (let i = 0; i < getOverdue.length; i++) {
              const item = getOverdue[i];
              renderedItems.push(
                <tbody className='h-[70px] font-[500] text-[16px] leading-[19.36px] text-[#464646] max-sm:text-[12px]'  style={{fontFamily:'Montserrat, sans-serif'}}>
                  <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 ">
                  <th scope="row" className="px-6 py-4 font-medium   w-[173px]  ">
                  {item.client_name}
          
                  </th>
                  <td className="px-6 py-4 text-[#050505] font-medium ">
                  {item.repo_name}
                  </td>
                    <td className="px-6 py-4  text-[#050505] ">
                    #{item.invoice_no}
                    </td>
                    <td className="px-6 py-4  text-[#3C3C3C]">
                    {item.invoice_date}
                    </td>
                    <td className="px-6 py-4  text-[#3C3C3C]">
                    &#8377; {item.ptr_amount}
                    </td>
                    <td className="px-6 py-4  text-[#3C3C3C]">
                    {item.trade_dis}
                    </td>
                    <td className="px-6 py-4  text-[#3C3C3C]">
                    &#8377; {item.gstpluscess}
                    </td>
                    <td className="px-6 py-4  text-[#3C3C3C] font-[600]">
                    &#8377; {item.total}
                    </td>
                    <td className="px-6 py-4  text-[#3C3C3C]">
                   {item.age}
                    </td>
            
                    <td className="px-6 py-4  ">
                    <div className=' w-[95px] flex justify-between max-sm:w-[85px] max-sm:ml-[5px] '>
                    {/* <span className="material-symbols-outlined font-[300]  cursor-pointer max-sm:text-[22px] no-underline text-[#1d1d1d] "  type="button" onClick={() => {setEditItem(item)}} > border_color </span> */}
                    {/* <span className="material-symbols-outlined font-[300]  cursor-pointer max-sm:text-[22px] no-underline text-[#1d1d1d] " type="button" onClick={() => {setDeleteItem(item)}}> delete</span> */}
                    { item.media_id && item.media_id != '' && item.media_id != null ?  <button onClick={() => {downloadImage("https://prod.viventure.inaipi.ae/api/"+item.media_path,item.media_name)}}> <span className="material-symbols-outlined font-[300]  cursor-pointer max-sm:text-[22px]  no-underline text-[black]" type="button">download</span> </button> :  <span className="material-symbols-outlined font-[300]  cursor-pointer max-sm:text-[22px]  no-underline text-[#8b8b8b] ">file_download_off</span>}
                        </div>
                    </td>
            
                  </tr>
                  </tbody>
              )
          }
      }else{
          renderedItems.push(<tbody className='h-[70px] font-[500] text-[16px] leading-[19.36px] text-[#464646]'>
              <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                  <th scope="row" className="px-6 py-4 font-medium  whitespace-nowrap ">
                  No Data Availabile
                  </th>
              </tr>
          </tbody>)
      }

      return renderedItems;
}


  // const handleStartDateChange = (date) => {
  //   setSDate(date);
 
  // };

  const handleEndDateChange = (date) => {
    setEDate(date);
    pageFilter({'rep':getSelectedRepoSort == '0' ? null : getSelectedRepoSort,'dist':getSelectedDistrictSort,'startd':getSDate,'endd':date,'hint':activeButton == 'btn1' ? 'all':'outstand'});
  };

// loader

const handleSaveClick = () => {
    FClearModelCancelBtn();
    setIsLoading(true);
    handleSubmit();
    setTimeout(() => {
      setIsLoading(false);  
    }, 2000); 
};

//edit loading

const handleloading_edit = () => {
  FClearEditModelCancelBtn();
  handleEditSubmit();
  setEdit_Loading(true);
  setTimeout(() => {
    setEdit_Loading(false);  
  }, 2000); 
};

function FClearEditModelCancelBtn() {
  setEdit_Loading(false);
  setEditDistrictError(false);
  setEditClientNameError(false);
  setEditRepresentativeError(false);
  setEditInvoiceNoError(false);
  setEditInvoiceDateError(false);
  setEditPTRAmountError(false);
  setEditTradeDiscountError(false);
  setEditGSTCessError(false);
  setEditTotalError(false);
  setEditPaymentError(false);
  setIsLoading(false);  
}

function FClearModelCancelBtn() {
  setSubmitBillStatusError(false);
  setSubmitTotalError(false); 
  setSubmitGSTCessError(false);
  setSubmitTradeDiscountError(false);
  setSubmitPTRAmountError(false);
  setSubmitInvoiceNoError(false);
  setSubmitInvoiceDateError(false);
  setSubmitRepoNameError(false);
  setSubmitClientError(false);
  setSubmitDistrictError(false);
  setIsLoading(false);  
}

// const appendTableData = () => {
//   const renderedItems = [];
//       console.log("=======getData:",getAllData);
//       if(getAllData.length != 0){
//           //console.log("=======+1:",getAllData.length);
//           for (let i = 0; i < getAllData.length; i++) {
//               const item = getAllData[i];
//               renderedItems.push(
//                 <tbody className='h-[70px] font-[500] text-[16px] leading-[19.36px] text-[#464646] max-sm:text-[12px]'  style={{fontFamily:'Montserrat, sans-serif'}}>
//                 <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
//                 <th scope="row" className="px-6 py-4 font-medium   w-[173px]  ">
//                 {item.client_name}
        
//                  </th>
//                  <td className="px-6 py-4 text-[#050505] w-[163px] ">
//                    {item.repo_name}
//                  </td>
//                   <td className="px-6 py-4  text-[#050505]">
//                     #{item.invoice_no}
//                   </td>
//                   <td className="px-6 py-4  text-[#3C3C3C]">
//                   {item.invoice_date}
//                   </td>
//                   <td className="px-6 py-4  text-[#3C3C3C]">
//                   &#8377; {item.ptr_amount}
//                   </td>
//                   <td className="px-6 py-4  text-[#3C3C3C]">
//                   {item.trade_dis}
//                   </td>
//                   <td className="px-6 py-4  text-[#3C3C3C]">
//                   &#8377; {item.gstpluscess}
//                   </td>
//                   <td className="px-6 py-4  text-[#3C3C3C] font-[600]">
//                   &#8377; {item.total}
//                   </td>
//                   {item.payment_id == 1 ?
//                   <td className="px-[28px] py-4  text-[#3C3C3C]">
//                   <div className='w-[65px] bg-[#DCFFC1] px-[16px] py-[4px] rounded-[43px]'>
//                     <h5 className='text-center font-[600] text-[14px] text-[#01570F] max-sm:text-[12px]'>Paid</h5>
//                   </div>
//                 </td>: 
//                 <td className="px-5 py-4 ]">
//                 <div className='w-[95px] bg-[#FFBEBE] px-[16px] py-[4px] rounded-[43px] max-sm:w-[85px]'>
//                   <h5 className='text-center font-[600] text-[14px] text-[#A8422B] max-sm:text-[12px]'>Unpaid</h5>
//                 </div>
//               </td>
//                   }
                  
                  
          
//                   <td className="px-6 py-4  ">
//                   <div className=' w-[95px] flex justify-between max-sm:w-[85px] max-sm:ml-[5px] '>
//                       <span className="material-symbols-outlined font-[300]  cursor-pointer max-sm:text-[22px]"  type="button" onClick={() => {setEditItem(item)}} > border_color </span>
//                     <span className="material-symbols-outlined font-[300]  cursor-pointer max-sm:text-[22px]" type="button" onClick={() => {setDeleteItem(item)}}> delete</span>
//                     { item.media_id != null ?  <button onClick={() => {downloadImage(item.media_full_path,item.media_name)}}> <span className="material-symbols-outlined font-[300]  cursor-pointer max-sm:text-[22px]" type="button">download</span> </button> :  <MaterialSymbol icon="." size={1} fill grade={-25} />}
//                       </div>
//                   </td>
          
//                 </tr>
//                   </tbody>
//               )
//           }
//       }else{
//           renderedItems.push(<tbody className='h-[70px] font-[500] text-[16px] leading-[19.36px] text-[#464646]'>
//               <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
//                   <th scope="row" className="px-6 py-4 font-medium  whitespace-nowrap ">
//                   No Data Availabile
//                   </th>
//               </tr>
//           </tbody>)
//       }

//       return renderedItems;
// }



  // to get first day of month

  const handleDateChange = (event) => {
    console.log("=======handleDateChange=:",event.target.value);
    setSDate(event.target.value);
    setSDateISO(moment(event.target.value).format());
  };


    // to get last day of month
   
    const handleendDateChange = (event) => {
      console.log("=======handleendDateChange=:",event.target.value);
      setEDate(event.target.value);
      setEDateISO(moment(event.target.value).format());
      pageFilter({'dist':getSelectedDistrictSort,'rep':getSelectedRepoSort == '0' ? null : getSelectedRepoSort,'startd':getSDate,'endd':event.target.value,'hint':activeButton == 'btn1' ? 'all':'outstand'});
    };

  

  return (
    <div className="bg-[#f8f8f9]" >
  
    <Navbar activePage="admin" />

   
   <div className='bg-[#f8f8f9] min-h-[100vh] px-[48px] pb-[80px]   pt-[86px]
                   max-sm:px-[20px] ' style={{overflowX:'hidden'}}>
   <div className='  mb-[20px]  relative'>
    <div className='h-[127px] w-[602px]  max-lg:h-[195px]  ' >
        <div className='h-[60px] w-[auto]   grid grid-cols-[40px,1fr] 
                         max-sm:grid-cols-[35px,1fr] '>
             <div className='h-[40px] w-[40px]
                              max-sm:w-[32px]   max-sm:h-[32px]   max-sm:mt-[6px]   '>
                <img src={Hand} alt=''/>
             </div>
             <div className='m-0   '>
             <h5 className='h-[48px] max-sm:h-[35px]  flex items-center font-[600] text-[24px] text-[#343434] 
                            max-sm:text-[16px] m-0 ' >Hi, {sessionStorage.getItem('USER_NAME')}</h5>
             <h6 className=' font-[500] text-[16px] text-[#6D6D6D]
                             max-sm:text-[12px] '>Welcome to Viventure</h6>
             </div>

        </div>

{screenWidth < 748 ?
  <div className="flex justify-end items-end">
  <div className='absolute right-0 top-0 mt-2   '>

{/* <button className="bg-[#F4CF7B] hover:bg-[#e7ca88] px-2.5 py-1.5 text-[#7A4310] font-[600] text-[12px]   border border-[#F4CF7B] rounded mr-2 "
onClick={()=>{
  FClearModelCancelBtn();
  setIsLoading(false);  
  setVisible(true);}}>

 <span className="material-symbols-outlined  text-[18px] ">upload</span>
 </button> */}
<button className="bg-[#FFFFFF] hover:bg-[#f1f0f0] text-[#353535] font-[600] text-[12px] h-[44px]  px-3 py-2 border border-[#DFDFDF] rounded mr-2" onClick={() => { generateCSVFile() }}>
<img className=' pt-[3px]' width={'18 px'} height={'18px'} src={Excel2} alt=''/>
  </button>
  <Link to='/pdf' style={{textDecoration:'none'}}>
  <button className="bg-[#FFFFFF] hover:bg-[#f1f0f0] text-[#353535] font-[600] text-[12px]   h-[44px]  px-3 py-2 border border-[#DFDFDF] rounded mr-2" >
  <img className=' pt-[3px]' width={'16px'} height={'18px'} src={Pdf2} alt=''/>
  </button>
  </Link>

</div>
</div>

:
<div className='absolute right-0 top-0 mt-3 flex items-center '>

{/* <button className="bg-[#F4CF7B] flex items-center hover:bg-[#e7ca88] text-[#7A4310] font-[600] text-[14px] py-2 px-3 border border-[#F4CF7B] rounded mr-3 "
onClick={()=>{
  FClearModelCancelBtn();
  setIsLoading(false);  
  setVisible(true);
  }}> <span className="material-symbols-outlined p-0 mr-1 text-[20px] ">upload</span>
    Upload New
 </button> */}
<button className=" flex items-center bg-[#FFFFFF] hover:bg-[#f1f0f0] text-[#353535] font-[600] text-[14px] py-2 px-4 mr-3 border border-[#DFDFDF] rounded"onClick={() => { generateCSVFile() }}>
<span className="material-symbols-outlined mr-1  text-[20px]">download</span>Sales Report
<img className='pl-2 pt-[3px]' width={'24px'} height={'24px'} src={Excel2} alt=''/></button>

<Link to='/pdf' style={{textDecoration:'none'}}>
<button className=" flex items-center bg-[#FFFFFF] hover:bg-[#f1f0f0] text-[#353535] font-[600] text-[14px] py-2 px-4   border border-[#DFDFDF] rounded " >
<span className="material-symbols-outlined mr-1  text-[20px]">download</span>Product Report
<img className='pl-2 pt-[3px]' width={'21px'} height={'20px'} src={Pdf2} alt=''/></button>
</Link>

</div>
}


    <div className=' date-repName-District h-[43px]    mt-[34px] max-sm:mt-[18px] flex max-lg:flex-wrap max-lg:w-[590px] max-lg:h-[100px]  max-md:w-[390px] justify-between gap-x-[20px] max-lg:gap-x-[12px] max-lg:gap-y-[5px]  max-sm:gap-x-[10px] max-sm:w-[315px] '>
     
          {/* calender */}
 {/* <div className='bg-[#ffffff] max-sm:px-[57px] z-100   flex items-center px-6 cursor-pointer rounded-[4px] max-lg:h-[45px] ' >
          
  <div   >
<DatePicker
        selected={sDate}
        onChange={handleStartDateChange}
        selectsStart
        startDate={sDate}
        endDate={eDate}
        dateFormat="dd/MM/yyyy"
        placeholderText="Start Date"
        width={30}
        className=' max-sm:text-[11px] max-sm:px-6  w-[105px] pl-3.5 text-[14px] h-[15px] font-[500]   '
       
        
      />
</div>
&minus;
 <div>
<DatePicker
        selected={eDate}
        onChange={handleEndDateChange}
        selectsEnd
        startDate={sDate}
        endDate={eDate}
        minDate={sDate}
        dateFormat="dd/MM/yyyy"
        placeholderText="End Date"
         className=' max-sm:text-[11px] max-sm:px-6   w-[105px] pl-3.5 text-[14px] h-[15px] font-[500]  '
              

        
       
      />
</div>

      </div> */}


      {/* calender to */}

      <div className='card1-date bg-white  w-[185px] max-sm:w-[150px]  rounded-[4px]  max-lg:h-[45px]  '>
        <div className=' card2-date rounded flex items-center w-[170px] max-sm:w-[140px] h-[42px] py-2  mx-2 font-[500] text-[14px] text-[#161616] leading-tight focus:outline-none focus:shadow-outline  max-sm:text-[11px]  max-sm:text-[#797777]  '>
          
        <h6 className='inline-block text-[12px] mt-2 text-center'>From:</h6>
        <input type="date" className=" date-tag-bg rounded w-[150px] max-sm:w-[150px] mt-[2px] h-[42px] py-2  mx-2 font-[500] text-[14px] text-[#161616] leading-tight focus:outline-none focus:shadow-outline  max-sm:text-[11px]  max-sm:text-[#797777] bg-white " id="to-calender" name="to-calender"  value={getSDate} onChange={handleDateChange} />
    
         
         
        </div>
      </div>


      {/* calender from */}

      <div className='card1-date bg-white  w-[185px] max-sm:w-[150px]  rounded-[4px]  max-lg:h-[45px]  '>

      <div className='card2-date rounded flex items-center w-[170px] max-sm:w-[140px] h-[42px] py-2  mx-2 font-[500] text-[14px] text-[#161616] leading-tight focus:outline-none focus:shadow-outline  max-sm:text-[11px]  max-sm:text-[#797777]  '>
          
          <h6 className='inline-block text-[12px] mt-2 text-center'>To:</h6>
        <input type="date" className=" date-tag-bg bg-[white] rounded w-[170px] max-sm:w-[150px] mt-[2px] h-[42px] py-2  mx-2 font-[500] text-[14px] text-[#161616] leading-tight focus:outline-none focus:shadow-outline  max-sm:text-[11px]  max-sm:text-[#797777] " id="from-calender" name="from-calender"  value={getEDate} onChange={handleendDateChange}/>
          </div>
        
      </div>








    

      {/* ------drop down button 1-------------- */}
      <div className='bg-white  w-[185px] max-sm:w-[150px]  rounded-[4px]  max-lg:h-[45px]  '>
        <div className=''>
          <select id="paid" name="carlist"  className=" bg-white select-tag-bg  rounded w-[170px] max-sm:w-[140px] h-[42px] py-2  mx-2 font-[500] text-[14px] text-[#161616] leading-tight focus:outline-none focus:shadow-outline  max-sm:text-[11px]  max-sm:text-[#797777] "  value={getSelectedRepoSort} onChange={handleRepoSortChange}>
            <option value="0" selected className="text-[#161616]  w-[185px] pl-[16px] py-2.5   inline-flex  ">Rep Name</option>
            {getRepo.map((item, index) => (<option value={item.id}>{item.name}</option>))}
          </select>
        </div>
      </div>
 

      {/* ------drop down button 2-------------- */}
      <div className='bg-white  w-[185px] max-sm:w-[150px] rounded-[4px]  max-lg:h-[45px]'>
        <div>
          <select id="paid" name="carlist" className=" bg-white select-tag-bg rounded w-[170px] max-sm:w-[140px]  h-[42px] py-2 mx-2 font-[500] text-[14px] text-[#161616] leading-tight focus:outline-none focus:shadow-outline  max-sm:text-[11px]  max-sm:text-[#797777] "  value={getSelectedDistrictSort} onChange={handleDistrictSortChange}>
            <option value="0" className="text-[#161616]  w-[185px] pl-[16px] py-2.5   inline-flex " selected >District</option>
            {getDistricts.map((item, index) => (<option value={item.id}>{item.name}</option>))}
          </select>
        </div>
      </div>
      
    </div>
    </div>

   <div className='h-[auto]  mt-[32px] max-sm:mt-[18px] '>


{/* --------------- */}



{/* ----------------- */}


    <div className='h-[185px]   w-auto max-lg:w-[700px] max-lg:h-[384px] max-lg:grid-cols-[1fr,1fr]  max-md:w-[550px] max-md:h-[325px]  max-sm:w-[335px] max-sm:h-[205px] max-sm:gap-x-[10px] grid grid-cols-[1fr,1fr,1fr,1fr] gap-x-[20px] max-2xl:grid-cols-[1fr,1fr,1fr]  max-2xl:h-[387px] gap-y-[25px] max-2xl:w-[1060px]  max-xl:w-[995px] max-md:gap-y-[5px]'>
        <div className='bg-[#ffffff] flex  justify-start relative rounded-[8px] max-lg:w-auto max-sm:h-[97px] max-2xl:h-[185px] max-md:h-[145px] max-lg:h-[184px] '>
            <div className='w-[auto] h-[86px] max-sm:h-[50px] max-sm:w-auto max-md:h-[74px]  mt-[49px] ml-[24px] max-lg:mt-[38px] max-sm:mt-[18px] max-md:mt-[28px] max-sm:ml-[13px] relative  '>
           <h5 className='font-[500] text-[18px] max-lg:text-[16px] max-md:text-[14px] max-sm:text-[9px] text-start text-[#797777] leading-[21.94px]' style={{fontFamily:'Montserrat, sans-serif',zIndex:'150'}}  >CLOSED DEALS</h5>
           <h1 className='font-[600] text-[36px] max-lg:text-[33px]  max-md:text-[30px] max-sm:text-[20px]   text-black leading-[48.76px]  max-sm:leading-[24.38px] absolute left-0 bottom-[0px] m-0 ' style={{fontFamily:'Montserrat, sans-serif'}} >{getClosedDealCount}</h1>
            </div>
            {screenWidth < 640 ?"":
           <div className='absolute w-[127px] h-[73px]  top-[90px] right-0 max-lg:w-[120px]  max-lg:h-[100px]    max-md:w-[90px] max-md:h-[64px] max-md:top-[78px]  max-md:right-[0px] max-sm:w-[71px] max-sm:h-[44px] max-sm:top-[53px] max-sm:left-[119px] '>
           <img className='object-cover rounded-br-[8px]'  src={Frame} alt=''/>
       </div>
            }
           
        </div>
        <div className='bg-[#ffff] relative rounded-[8px] max-sm:h-[97px] max-2xl:h-[185px] max-lg:h-[184px] max-md:h-[145px]'>
        <div className='w-[auto] h-[86px] mt-[49px] ml-[24px] relative max-sm:h-[50px] max-sm:w-auto max-sm:mt-[18px] max-sm:ml-[13px] max-lg:mt-[38px] max-md:h-[74px] max-md:mt-[28px]  '>
           <h5 className='font-[500] text-[18px] max-lg:text-[16px] max-md:text-[14px] max-sm:text-[9px] text-start text-[#797777] leading-[21.94px]' style={{fontFamily:'Montserrat, sans-serif',zIndex:'150'}}  >TOTAL  AMOUNT CLOSED</h5>
           <h1 className='font-[600] text-[36px] max-lg:text-[33px] max-md:text-[30px] max-sm:text-[20px] text-black leading-[48.76px] max-sm:leading-[24.38px] absolute left-0 bottom-[0px] m-0 ' style={{fontFamily:'Montserrat, sans-serif'}} > &#8377; {getClosedAmount}</h1>
            </div>

            {screenWidth < 640 ?"":
            <div className='absolute w-[127px] h-[73px]  top-[108px] right-0 max-lg:w-[120px]  max-lg:h-[100px]    max-md:w-[90px] max-md:h-[64px] max-md:top-[91px] max-md:right-[0px] max-sm:w-[71px] max-sm:h-[44px] max-sm:top-[53px] max-sm:left-[119px] '>
                <img className='object-cover rounded-br-[8px]'  src={Frame2} alt=''/>
            </div>
             }
        </div>

        <div className='bg-[#ffff] relative rounded-[8px] max-sm:h-[97px] max-2xl:h-[185px] max-lg:h-[184px] max-md:h-[145px]'>
        <div className='  w-[auto] h-[86px] mt-[49px] ml-[24px] relative max-sm:h-[50px] max-sm:w-auto max-sm:mt-[18px] max-sm:ml-[13px] max-lg:mt-[38px] max-md:h-[74px] max-md:mt-[28px]  '>
           <h5 className='font-[500] text-[18px] max-lg:text-[16px] max-md:text-[14px] max-sm:text-[9px] text-start text-[#797777] leading-[21.94px]' style={{fontFamily:'Montserrat, sans-serif',zIndex:'150'}}  >OUTSTANDING</h5>
           <h1 className='font-[600] text-[36px] max-lg:text-[33px] max-md:text-[30px] max-sm:text-[20px] text-black leading-[48.76px] max-sm:leading-[24.38px] absolute left-0 bottom-[0px] m-0 ' style={{fontFamily:'Montserrat, sans-serif'}} >{getOutStandingCount}</h1>
            </div>
            <div className=' w-[auto] h-[auto] mt-[8px] ml-[24px]
                             max-sm:ml-[13px] max-sm:mt-[1px] '>

            <h6 className='font-[500] text-[16px] max-md:text-[14px] leading-[19.5px] text-[#A7801A]
                           max-sm:text-[12px]' >Amount- &#8377; {getOutStandingAmount}</h6>
            </div>

            {screenWidth < 640 ?"":
            <div className='  absolute w-[78px] h-[73px]  top-[121px] right-0 max-lg:w-[82px]  max-lg:h-[82px] max-lg:top-[115px]    max-md:w-[70px] max-md:h-[64px] max-md:top-[87px] max-md:right-[0px] max-sm:w-[71px] max-sm:h-[44px] max-sm:top-[53px] max-sm:left-[119px] '>
                <img className='object-cover rounded-br-[8px]'  src={Frame3} alt=''/>
            </div>
            }
        </div>

        <div className='bg-[#ffff] relative rounded-[8px] max-sm:h-[97px] max-2xl:h-[185px] max-lg:h-[184px] max-md:h-[145px]'>
        <div className='w-[auto] h-[86px] mt-[49px] ml-[24px] relative max-sm:h-[50px] max-sm:w-auto max-sm:mt-[18px] max-sm:ml-[13px] max-lg:mt-[38px] max-md:h-[74px] max-md:mt-[28px]  '>
           <h5 className='font-[500] text-[18px] max-lg:text-[16px] max-md:text-[14px] max-sm:text-[9px] text-start text-[#797777] leading-[21.94px]' style={{fontFamily:'Montserrat, sans-serif',zIndex:'150'}}  >OVERDUE</h5>
           <h1 className='font-[600] text-[36px] max-lg:text-[33px] max-md:text-[30px] max-sm:text-[20px] text-black leading-[48.76px] max-sm:leading-[24.38px] absolute left-0 bottom-[0px] m-0 ' style={{fontFamily:'Montserrat, sans-serif'}} >{getOverDueCount}</h1>
            </div>
                  <div className=' w-[auto] h-[auto] mt-[8px] ml-[24px]
                                  max-sm:ml-[13px] max-sm:mt-[1px] '>
                <h6 className='font-[500] text-[16px] leading-[19.5px] max-md:text-[14px] text-[#E62424]
                                max-sm:text-[12px]' >Amount- &#8377; {getOverDueAmount}</h6>
                </div>
                {screenWidth < 640 ?"":
            <div className='absolute w-[103px] h-[154px] top-[110px] right-0 max-lg:w-[100px]  max-lg:h-[100px]   max-lg:top[90px]  max-md:h-[64px] max-md:top-[71px] max-md:right-[0px] max-sm:w-[71px] max-sm:h-[44px] max-sm:top-[53px] max-sm:left-[119px] '>
                <img className='object-cover rounded-br-[8px]'  src={Frame4} alt=''/>
            </div>
                }
        </div>

    </div>


{/* --------table view condition */}
<div className=' '>
<div className="card-header">
        <div className="btn-group flex justify-between mt-[24px] relative  h-[40px] w-[210px] " role="group" aria-label="Button group">
         

   {/* loader table */}
       <div className='block absolute top-0 left-5'>
          {loader? <div><div role="status"className=' '>
           <svg aria-hidden="true" className="w-8 h-8  text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
             <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
             <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
           </svg>
           <span className="sr-only">Loading...</span>
           </div></div>
          :<div></div>}
        </div>
<button
    type="button"
    className={`btn ${activeButton === 'btn1' ? 'active' : 'nonactive'}`}
    onClick={() => {
      pageFilter({'rep':getSelectedRepoSort == '0' ? null : getSelectedRepoSort,'dist':getSelectedDistrictSort,'startd':getSDate,'endd':getEDate,'hint': 'all'});
      setActiveButton('btn1');
      // handleButtonClick('btn1'); 
      setLoader(true);}}
  >
    All List
  </button>
 


  {/* loader table */}
  <div className='block absolute top-0 right-12'>
          {OutstandingLoader? <div><div role="status"className=' '>
           <svg aria-hidden="true" className="w-8 h-8  text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
             <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
             <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
           </svg>
           <span className="sr-only">Loading...</span>
           </div></div>
          :<div></div>}
        </div>
  <button
    type="button"
    className={`btn ${activeButton === 'btn2' ? 'active' : 'nonactive'}`}
    onClick={() => {
      pageFilter({'rep':getSelectedRepoSort == '0' ? null : getSelectedRepoSort,'dist':getSelectedDistrictSort,'startd':getSDate,'endd':getEDate,'hint': 'outstand'});
      setActiveButton('btn2');
      // handleButtonClick('btn2'); 
      setOutstandingLoader(true);}}
  >
    Outstanding
  </button>

        </div>
      </div>
      <div className="">
        {activeButton === 'btn1' && (
          <div>
              {/* ---- All List table------------- */}
              {/* <AllSaleList data={getAllData}/> */}
                              

              <div className= ' bottom-div-with-hidden-scrollbar h-auto  mt-[24px] overflow-x-auto' >
                  <div className="relative ">
                      <table className="w-full text-sm text-left rtl:text-right ">
                          <thead className="bg-[#FFFAEE] text-[#393939] text-[14px] font-[500] h-[67px] leading-[16.94px] max-sm:text-[12px]" style={{whiteSpace:'nowrap'}}>
                              <tr>
                                  <th scope="col" className="px-6 py-3  ">
                                      Client name
                                  </th>
                                  <th scope="col" className="px-6 py-3  ">
                                      Rep Name
                                  </th>
                                  <th scope="col" className="px-6 py-3">
                                      Invoice No:
                                  </th>
                                  <th scope="col" className="px-6 py-3">
                                      Invoice Date
                                  </th>
                                  <th scope="col" className="px-6 py-3">
                                      PTR amount
                                  </th>
                                  <th scope="col" className="px-6 py-3">
                                      Trade Disc
                                  </th>
                                  <th scope="col" className="px-6 py-3">
                                      GST + Cess
                                  </th>
                                  <th scope="col" className="px-6 py-3">
                                  Total
                                  </th>
                                  <th scope="col" className="px-[35px] py-3">
                                  Status
                                  </th>
                                  <th scope="col" className="px-[45px] py-3">
                                  Action
                                  </th>
                              </tr>
                          </thead>
                          {appendTableData()}
                      </table>
                  </div>
              </div>
              {/* ---- All List table  end------------- */}
          </div>
        )}
        {activeButton === 'btn2' && (
          <div>
            {/* ---- Outstanding table------------- */}
            {/* <OutStandingList outstanding={getOutStanding} overdue={getOverdue}/> */}
            <div className= ' bottom-div-with-hidden-scrollbar h-auto  mt-[24px]  overflow-x-auto' >
            <div className="relative ">
                <table className="w-full text-sm text-left rtl:text-right">
                <thead className="bg-[#FFFAEE] text-[#393939] text-[14px] font-[500] h-[67px] leading-[16.94px] max-sm:text-[12px]" style={{whiteSpace:'nowrap'}} >
                    <tr>
                    <th scope="col" className="px-6 py-3">
                        Client name
                    </th>
                    <th scope="col" className="px-6 py-3">
                        Rep Name
                    </th>
                    <th scope="col" className="px-6 py-3">
                        Invoice No:
                    </th>
                    <th scope="col" className="px-6 py-3">
                        Invoice Date
                    </th>
                    <th scope="col" className="px-6 py-3">
                        PTR amount
                    </th>
                    <th scope="col" className="px-6 py-3">
                        Trade Disc
                    </th>
                    <th scope="col" className="px-6 py-3">
                        GST + Cess
                    </th>
                    <th scope="col" className="px-6 py-3">
                    Total
                    </th>
                    <th scope="col" className="px-6 py-3">
                    Outstanding
                    </th>
                    <th scope="col" className="px-[45px] py-3">
                    Action
                    </th>
                    </tr>
                </thead>
                {appendOutStandingableData()}
                </table>
            </div>
        </div>
            {/* ---- Outstanding table  end------------- */}
          </div>
        )}
      </div>


{/* -----------pagination----------- */}


  
<div className='mt-5 flex justify-end max-sm:justify-center '>

<Stack spacing={1} >

{/* {screenWidth < 640 ?
<Pagination count={10} variant="outlined" size="small"  />
:
 <Pagination count={10} variant="outlined"  />
}
       */}
    <Pagination
                nPages={nPages}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
            />
    </Stack>

</div>





</div>
  
   </div>


   </div>
   </div>


   <div>

  {/* --calender modal */}
   <Modal onCancel = {()=> setShowCalender(false)}  footer={null}  visible={showCalender} width={'310px'} top={0}>
        <div className="modal pt-[30px] pl-[10px] ">
          <div className="modal-content">
            <DatePicker
              selected={startDate}
              onChange={handleDateSelect}
              selectsStart
              startDate={startDate}
              endDate={endDate}
              inline
            />
          </div>
          <div className='flex justify-between mt-[5px] px-1'>
            <button onClick={handleModalClose}>Cancel</button>
            <button className='pr-[12px]' onClick={handleOkButtonClick}>OK</button>
          </div>
        </div>
    </Modal>

    
    {/* --calender modal end--------- */}  


    
    {/* -----upload new modal---- */}
          
            <Modal onCancel = {()=> {setVisible(false); FClearModelCancelBtn();}} 
            footer={null} 
            visible={visible}
            width={'438px'}
            top={0}>

    <div className='pt-[20px] py-[24px]'style={{fontFamily:'Montserrat, sans-serif'}}>
        <div className='w-auto '>
            <h1 className='font-[600] text-[20px] leading-[24.38px] text-[#1C1C1C] mb-[24px]'>Add Invoice Details</h1>

            {/* --------form------------ */}
            <div className='w-auto '>
            <div>
                    <div className="mb-4">
                        <label className="block text-[#32324D] text-[12px] font-[400] leading-[16px] mb-2" >
                            District
                        </label>
                        <div className='bg-[#FAFAFA]  border border-[#E3E3E3]  rounded w-full h-[40px] py-2 pl-2 pr-2'>
                          <select id="paid"  className=" bg-[#FAFAFA]  w-full   text-gray-700 leading-tight focus:outline-none focus:shadow-outline"  value={getSelectedDistrict} onChange={handleDistrictChange}>
                         <option  value="none" className='my-5' selected>Select District</option>
                         {getDistricts.map((item, index) => (<option value={item.id}>{item.name}</option>))}
                          </select>
                          {getSubmitDistrictError && <div style={{color: 'red', fontSize: '12px',marginTop:'10px',marginBottom:'15px'}}>{getSubmitDistrictErrorMessage}</div>}
                          </div>
                        </div>
                        <div className="mb-4">
                        <label className="block text-[#32324D] text-[12px] font-[400] leading-[16px] mb-2" >
                            Client name
                        </label>
                        <input className=" border border-[#DCDCE4] rounded w-full h-[40px] py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="username" type="text"  value={getSelectedClientName} onChange={handleClientNameChange}/>
                        {getSubmitClientError && <div style={{color: 'red', fontSize: '12px',marginTop:'10px',marginBottom:'15px'}}>{getSubmitClientErrorMessage}</div>}
                        </div>
                        <div className="mb-4">
                        <label className="block text-[#32324D] text-[12px] font-[400] leading-[16px] mb-2" >
                            Representative name
                        </label>
                        <div className='bg-[#FAFAFA]  border border-[#E3E3E3]  rounded w-full h-[40px] py-2 pl-2 pr-2'>
                          <select id="paid"  className=" bg-[#FAFAFA]  w-full   text-gray-700 leading-tight focus:outline-none focus:shadow-outline" value={getSelectedRepresentativeName} onChange={handleRepresentativeNameChange} >
                         <option  value="none" selected>Select Rep Name</option>
                         {getRepo.map((item, index) => (<option value={item.id}>{item.name}</option>))}
                          </select>
                          {getSubmitRepoNameError && <div style={{color: 'red', fontSize: '12px',marginTop:'10px',marginBottom:'15px'}}>{getSubmitRepoNameErrorMessage}</div>}
                          </div>
                        </div>
                        <div className="mb-4">
                        <label className="block text-[#32324D] text-[12px] font-[400] leading-[16px] mb-2" >
                          Invoice No
                        </label>
                        <input className=" border border-[#DCDCE4] rounded w-full h-[40px] py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="username" type="text"  value={getSelectedInvoiceNo} onChange={handleInvoiceNoChange}/>
                        {getSubmitInvoiceNoError && <div style={{color: 'red', fontSize: '12px',marginTop:'10px',marginBottom:'15px'}}>{getSubmitInvoiceNoErrorMessage}</div>}
                        </div>
                        <div className="mb-4">
                        <label className="block text-[#32324D] text-[12px] font-[400] leading-[16px] mb-2" >
                            Invoice Date
                        </label>
                        <input className=" border border-[#DCDCE4] rounded w-full h-[40px] py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="username" type="date" value={getSelectedInvoiceDate} onChange={handleInvoiceDateChange} />
                        {getSubmitInvoiceDateError && <div style={{color: 'red', fontSize: '12px',marginTop:'10px',marginBottom:'15px'}}>{getSubmitInvoiceDateErrorMessage}</div>}
                        </div>
                        <div className="mb-4">
                        <label className="block text-[#32324D] text-[12px] font-[400] leading-[16px] mb-2" >
                            PTR Amount
                        </label>
                        <input className=" border border-[#DCDCE4] rounded w-full h-[40px] py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="username" type="text" autoComplete='off'   min="0" value={getSelectedPTRAmount} onChange={handlePTRAmountChange} />
                        {getSubmitPTRAmountError && <div style={{color: 'red', fontSize: '12px',marginTop:'10px',marginBottom:'15px'}}>{getSubmitPTRAmountErrorMessage}</div>}
                        </div>
                        <div className="mb-4">
                        <label className="block text-[#32324D] text-[12px] font-[400] leading-[16px] mb-2" >
                            Trade Discount
                        </label>
                        <input className=" border border-[#DCDCE4] rounded w-full h-[40px] py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="username" type="text" autoComplete='off'  min="0" value={getSelectedTradeDiscount} onChange={handleTradeDiscountChange} />
                        {getSubmitTradeDiscountError && <div style={{color: 'red', fontSize: '12px',marginTop:'10px',marginBottom:'15px'}}>{getSubmitTradeDiscountErrorMessage}</div>}
                        </div>
                        <div className="mb-4">
                        <label className="block text-[#32324D] text-[12px] font-[400] leading-[16px] mb-2" >
                            GST + Cess
                        </label>
                        <input className=" border border-[#DCDCE4] rounded w-full h-[40px] py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="username" type="text"  autoComplete='off'   min="0" value={getSelectedGSTPlusCess} onChange={handleGSTPlusCessChange}/>
                        {getSubmitGSTCessError && <div style={{color: 'red', fontSize: '12px',marginTop:'10px',marginBottom:'15px'}}>{getSubmitGSTCessErrorMessage}</div>}
                        </div>
                        <div className="mb-4">
                        <label className="block text-[#32324D] text-[12px] font-[400] leading-[16px] mb-2" >
                            Total
                        </label>
                        <input className=" border border-[#DCDCE4] rounded w-full h-[40px] py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="username" type="text" autoComplete='off'  min="0" value={getSelectedTotal} onChange={handleTotalChange}/>
                        {getSubmitTotalError && <div style={{color: 'red', fontSize: '12px',marginTop:'10px',marginBottom:'15px'}}>{getSubmitTotalErrorMessage}</div>}
                        </div>
                        <div className="mb-4">
                        <label className="block text-[#32324D] text-[12px] font-[400] leading-[16px] mb-2" >
                            Bill Status
                        </label>
                        <div className='bg-[#FAFAFA]  border border-[#E3E3E3]  rounded w-full h-[40px] py-2 pl-2 pr-2'>
                          <select id="paid"  className=" bg-[#FAFAFA]  w-full   text-gray-700 leading-tight focus:outline-none focus:shadow-outline"  value={getSelectedBillStatus} onChange={handleBillStatusChange}>
                          <option  value="none" selected>Choose Bill Status</option>
                          {getPayment.map((item, index) => (<option value={item.id}>{item.name}</option>))}
                            </select>
                            {getSubmitBillStatusError && <div style={{color: 'red', fontSize: '12px',marginTop:'10px',marginBottom:'15px'}}>{getSubmitBillStatusErrorMessage}</div>}
                            </div>
                          </div>
                        {getSubmitError && <div style={{color: 'red', fontSize: '12px'}}>{getSubmitErrorMessage}</div>}
                        <div className='mt-[40px]  h-[49px] flex items-center relative'>
                        <label htmlFor="upload" className="font-[500] text-[16px] leading-[19.5px] text-[#A97604] max-sm:text-[12px] cursor-pointer">
                                    {fileName ? fileName : 'Upload Bill'}
                                  </label>
                                  <input id="upload" type="file" className="hidden" onChange={handleFileChange} />
                            <div className='absolute  right-0 w-[215px] h-[49px]   '>
                        
                            <button className="bg-[#ffff] absolute right-[105px] hover:bg-[#fcfaf7] text-black font-[400] text-[14px]  px-4 border border-[#EBEBEB] rounded mr-3 mt-0 h-[49px] w-[98px]  " onClick={ () => {
  setVisible(false);
  FClearModelCancelBtn()}}>Cancel</button>
                          
                        
                         

<button className='bg-[#F4CF7B] absolute right-0 hover:bg-[#e7ca88] text-[#7A4310] font-[600] text-[14px] border border-[#F4CF7B] w-[105px] h-[48px] rounded m-0 ' type={'submit'} onClick={handleSaveClick}>
  
{isLoading ?  <div role="status"className='ml-[35px]  '>
                                        <svg aria-hidden="true" className="w-8 h-8  text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                          <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                                          <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
                                        </svg>
                                        <span className="sr-only">Loading...</span>
                                      </div>

                                        :'Save'}
  </button>


                              
                             


                            </div>
                        </div>
 
                    </div>
            </div>

            {/* --------form  end------------ */}
        </div>
    </div>
    
    
    </Modal>
 {/* -----upload new modal end ---- */}   


    {/* -----edit table modal---- */}
          
    <Modal onCancel = {()=>  {setEdit(false);  setEdit_Loading(false);  FClearEditModelCancelBtn();} } 
    footer={null} 
    visible={edit}
    width={'438px'}
    top={0}>



    <div className='pt-[20px] py-[24px]'style={{fontFamily:'Montserrat, sans-serif'}}>
        <div className='w-auto '>
            <h1 className='font-[600] text-[20px] leading-[24.38px] text-[#1C1C1C] mb-[24px]'>Edit Invoice Details</h1>

            {/* --------form------------ */}
            <div className='w-auto '>
            <div>
                    <div className="mb-4">
                        <label className="block text-[#32324D] text-[12px] font-[400] leading-[16px] mb-2" >
                            District
                        </label>
                        <div className='bg-[#FAFAFA]  border border-[#E3E3E3]  rounded w-full h-[40px] py-2 pl-2 pr-2'>
                          <select id="paid"  className=" bg-[#FAFAFA]  w-full   text-gray-700 leading-tight focus:outline-none focus:shadow-outline"  value={getEditSelectedDistrict} onChange={handleEditDistrictChange} style={{backgroundColor:'white'}} >
                         <option  value="none" selected disabled hidden>Select District</option>
                         {getDistricts.map((item, index) => (<option value={item.id}>{item.name}</option>))}
                          </select>
                          </div>
                        </div>
                        {getEditDistrictError && <div style={{color: 'red', fontSize: '12px', marginTop: '10px'}}>{getEditDistrictErrorMessage}</div>}
                        <div className="mb-4">
                        <label className="block text-[#32324D] text-[12px] font-[400] leading-[16px] mb-2" >
                            Client name
                        </label>
                        <input className=" border border-[#DCDCE4] rounded w-full h-[40px] py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="username" type="text"  value={getEditSelectedClientName} onChange={handleEditClientNameChange}/>
                        </div>
                        {getEditClientNameError && <div style={{color: 'red', fontSize: '12px', marginTop: '10px'}}>{getEditClientNameErrorMessage}</div>}
                        <div className="mb-4">
                        <label className="block text-[#32324D] text-[12px] font-[400] leading-[16px] mb-2" >
                            Representative name
                        </label>
                        <div className='bg-[#FAFAFA]  border border-[#E3E3E3]  rounded w-full h-[40px] py-2 pl-2 pr-2'>
                          <select id="paid"  className=" bg-[#FAFAFA]  w-full   text-gray-700 leading-tight focus:outline-none focus:shadow-outline"  value={getEditSelectedRepresentativeName} onChange={handleEditRepresentativeNameChange} >
                         <option  value="none" selected disabled hidden>Select Rep Name</option>
                         {getRepo.map((item, index) => (<option value={item.id}>{item.name}</option>))}
                          </select>
                          </div>
                        </div>
                        {getEditRepresentativeError && <div style={{color: 'red', fontSize: '12px', marginTop: '10px'}}>{getEditRepresentativeErrorMessage}</div>}
                        <div className="mb-4">
                        <label className="block text-[#32324D] text-[12px] font-[400] leading-[16px] mb-2" >
                          Invoice No
                        </label>
                        <input className=" border border-[#DCDCE4] rounded w-full h-[40px] py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="username"   type="text"  value={getEditSelectedInvoiceNo} onChange={handleEditInvoiceNoChange}/>
                        </div>
                        {getEditInvoiceNoError && <div style={{color: 'red', fontSize: '12px', marginTop: '10px', marginTop: '10px'}}>{getEditInvoiceNoErrorMessage}</div>}
                        <div className="mb-4">
                        <label className="block text-[#32324D] text-[12px] font-[400] leading-[16px] mb-2" >
                            Invoice Date
                        </label>
                        <input className=" border border-[#DCDCE4] rounded w-full h-[40px] py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="username" type="date" value={getEditSelectedInvoiceDate} onChange={handleEditInvoiceDateChange} />
                        </div>
                        {getEditInvoiceDateError && <div style={{color: 'red', fontSize: '12px', marginTop: '10px'}}>{getEditInvoiceDateErrorMessage}</div>}
                        <div className="mb-4">
                        <label className="block text-[#32324D] text-[12px] font-[400] leading-[16px] mb-2" >
                            PTR Amount
                        </label>
                        <input className=" border border-[#DCDCE4] rounded w-full h-[40px] py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="username"  type="text" autoComplete='off'  min="0" value={getEditSelectedPTRAmount} onChange={handleEditPTRAmountChange} />
                        </div>
                        {getEditPTRAmountError && <div style={{color: 'red', fontSize: '12px', marginTop: '10px'}}>{getEditPTRAmountErrorMessage}</div>}
                        <div className="mb-4">
                        <label className="block text-[#32324D] text-[12px] font-[400] leading-[16px] mb-2" >
                            Trade Discount
                        </label>
                        <input className=" border border-[#DCDCE4] rounded w-full h-[40px] py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="username" type="text" autoComplete='off'  min="0" value={getEditSelectedTradeDiscount} onChange={handleEditTradeDiscountChange} />
                        </div>
                        {getEditTradeDiscountError && <div style={{color: 'red', fontSize: '12px', marginTop: '10px'}}>{getEditTradeDiscountErrorMessage}</div>}
                        <div className="mb-4">
                        <label className="block text-[#32324D] text-[12px] font-[400] leading-[16px] mb-2" >
                            GST + Cess
                        </label>
                        <input className=" border border-[#DCDCE4] rounded w-full h-[40px] py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="username" type="text" autoComplete='off'  min="0" value={getEditSelectedGSTPlusCess} onChange={handleEditGSTPlusCessChange}/>
                        </div>
                        {getEditGSTCessError && <div style={{color: 'red', fontSize: '12px', marginTop: '10px'}}>{getEditGSTCessErrorMessage}</div>}
                        <div className="mb-4">
                        <label className="block text-[#32324D] text-[12px] font-[400] leading-[16px] mb-2" >
                            Total
                        </label>
                        <input className=" border border-[#DCDCE4] rounded w-full h-[40px] py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="username" type="text" autoComplete='off'  min="0" value={getEditSelectedTotal} onChange={handleEditTotalChange}/>
                        </div>
                        {getEditTotalError && <div style={{color: 'red', fontSize: '12px', marginTop: '10px'}}>{getEditTotalErrorMessage}</div>}
                        <div className="mb-4">
                        <label className="block text-[#32324D] text-[12px] font-[400] leading-[16px] mb-2" >
                            Bill Status
                        </label>
                        <div className='bg-[#FAFAFA]  border border-[#E3E3E3]  rounded w-full h-[40px] py-2 pl-2 pr-2'>
                          <select id="paid"  className=" bg-[#FAFAFA]  w-full  text-gray-700 leading-tight focus:outline-none focus:shadow-outline"  value={getEditSelectedBillStatus} onChange={handleEditBillStatusChange}>
                         <option  value="none" selected disabled hidden>Choose Bill Status</option>
                         {getPayment.map((item, index) => (<option value={item.id}>{item.name}</option>))}
                          </select>
                          </div>

                        </div>
                        {getEditPaymentError && <div style={{color: 'red', fontSize: '12px', marginTop: '10px'}}>{getEditPaymentErrorMessage}</div>}
                        {getEditSubmitError && <div style={{color: 'red', fontSize: '12px', marginTop: '10px'}}>{getEditSubmitErrorMessage}</div>}
                        <div className='mt-[40px]  h-[49px] flex items-center relative'>
                                  <label htmlFor="edit" className="font-[500] text-[16px] leading-[19.5px] text-[#A97604] max-sm:text-[12px] cursor-pointer ">
                                    {editFileName ? editFileName : 'Edit Bill'}
                                  </label>
                                  <input id="edit" type="file" className="hidden" onChange={handleEditFileChange} />
                            {/* <h4 className='font-[500] text-[16px] leading-[19.5px] text-[#A97604] max-sm:text-[12px]'>Upload Bill</h4> */}
                            <div className='absolute right-0 w-[203] h-[49px]   '>
                            <button className="bg-[#ffff] absolute right-[125px] hover:bg-[#fcfaf7] text-black font-[400] text-[14px]  px-4 border border-[#EBEBEB] rounded mr-3 mt-0 h-[49px] w-[98px]  " onClick={ () => {
  setEdit_Loading(false);
  setEdit(false);
  FClearEditModelCancelBtn()}}>Cancel</button>
                          
                        
                       


<button className='bg-[#F4CF7B] absolute right-0 hover:bg-[#e7ca88] text-[#7A4310] font-[600] text-[14px] border border-[#F4CF7B] w-[125px] h-[48px] px-2 rounded m-0 ' type={'submit'} onClick={handleloading_edit} >

{edit_Loading?  <div role="status"className='ml-[35px]  '>
                                      <svg aria-hidden="true" className="w-8 h-8  text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                                        <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
                                      </svg>
                                      <span className="sr-only">Loading...</span>
                                    </div>

                                      :'Save change'}
</button>


                            </div>
                        </div>
 
                    </div>
            </div>

            {/* --------form  end------------ */}
        </div>
    </div>
    
    
    </Modal>
 {/* -----edit table modal end ---- */}   
{/* User Delete Model */}
<Modal onCancel = {()=> setDelete(false)}  footer={null}  visible={getDelete} width={460} top={0}>
                <div className='pt-[20px] py-[24px]'style={{fontFamily:'Montserrat, sans-serif'}}>
                    <div className='w-[auto] '>
                        <h1 className='font-[600] text-[20px] leading-[24.38px] text-[#1C1C1C] mb-[24px]'>Delete User</h1>
                        <hr></hr>
                        <div className='w-[auto] '>
                        <h3 className='font-[300] text-[18px] leading-[24.38px] text-[#1C1C1C] mb-[24px]'>Are you sure you need to delete invoice no.{getSelectInvoiceNo}</h3>
                            <div className='mt-[40px]  h-[49px] flex items-center relative'>
                                <div className='absolute right-0 w-[203] h-[49px]   '>
                                    <button className="bg-[#ffff] absolute right-[105px] hover:bg-[#fcfaf7] text-black font-[400] text-[14px]  px-4 border border-[#EBEBEB] rounded mr-3 mt-0 h-[49px] w-[98px] "onClick={() => setDelete(false)}>No</button>
                                    <button className="bg-[#F4CF7B] absolute right-0 hover:bg-[#e7ca88] text-[#7A4310] font-[600] text-[14px] border border-[#F4CF7B] w-[105px] h-[48px] rounded m-0" onClick={() => submitDeleteItem()}>Yes</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>

 
        <ErrorModel
          setVisible={setErrorModelVisible}
          getVisible={getErrorModelVisible}
          message={getErrorModelMessage}/>

        </div>

    </div>
  )
}

export default StateHead
