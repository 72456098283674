import React, {useState,useEffect} from 'react';
import {Link, useNavigate} from 'react-router-dom';
import '../Assets/css/App.css';
import {statusChangeUrls} from '../Commons/Urls';
import moment from "moment";
import Logo from '../Assets/images/logo.png';
import { Modal } from 'antd';
import {NavLink} from 'react-router-dom';


function Navbar({ activePage }) {
  const navigate = useNavigate();
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [visible,setVisible ] = useState(false);
  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);


  function getFirstChar(str) {
    const firstChars = str
      .split(' ')
      .map(word => word[0])
      .join('');
  
    return firstChars;
  }


  const UsersOnClick = () => navigate('/usermanagement');
  const producrOnClick = () => navigate('/productlist');
  const clientsOnClick = () => navigate('/client');

  const HomeOnClick = () => {
    switch (sessionStorage.getItem('USER_ROLE')) {
      case "1":navigate('/superadminhome'); break;
      case "2":navigate('/admin'); break;
      case "3":navigate('/stateheadhome'); break;
      case "4":navigate('/managershome'); break;
      case "5":navigate('/representativehome'); break;
      default:
        navigate('/login');
        break;
    }
  }

  function handleLogout() {
    sessionStorage.clear();
    navigate('/login');
  }

  return (
      <div>
  <div
    className="fixed top-0 left-0 right-0"
    style={{ fontFamily: "Montserrat, sans-serif ", zIndex: "1000" }}
  >
    <div className="h-[70px] bg-white flex items-center relative w-auto  ">
      <div
        className="w-[79px] h-[45px] ml-[48px] 
                          max-sm:ml-[20px]"
      >
        <img src={Logo} alt="" />
      </div>
      {sessionStorage.getItem('USER_ROLE') != '1' ?
      <div>
        {
        screenWidth < 500 ? (
          <div
            className="fixed bottom-0 left-0 right-0"
            style={{ fontFamily: "Montserrat, sans-serif ", zIndex: "1000" }}
          >
            <div className="h-[70px] bg-[#ffffff] flex items-center justify-between relative w-auto border-t-1 border " >
              <div className="  flex justify-between  max-md:ml-[80px] max-sm:mx-[20px] max-sm:mt-[5px] pl-[0px] w-[100%] cursor-pointer ">
              {sessionStorage.getItem('USER_ROLE') == '2' ? (<h5
                  className={`font-bold text-[14px]  ${
                    activePage === "admin"
                      ? "text-[#CC9306] font-bold"
                      : "text-[#7C7C7C]"
                  } max-sm:text-[11px] font-[250] text-center   cursor-pointer`}
                  onClick={HomeOnClick}>
                    Sales Report </h5>):(<div></div>)}
                    {sessionStorage.getItem('USER_ROLE') == '2' ? (<h6 className="ml-[33px] text-[#45464477]">|</h6>):(<div></div>)}
                {sessionStorage.getItem('USER_ROLE') == '2' ? (<h5
                  className={`font-bold text-[14px] ${
                    activePage === "adminlistusers"
                      ? "text-[#CC9306] font-bold"
                      : "text-[#7C7C7C]"
                  } max-sm:text-[11px] font-[250] text-center ml-[32px] cursor-pointer`}
                  onClick={UsersOnClick}
                >
                  User Management
                </h5>):(<div></div>)}
                {sessionStorage.getItem('USER_ROLE') == '2' ? (<h6 className="ml-[33px] text-[#45464477]">|</h6>):(<div></div>)}
                {sessionStorage.getItem('USER_ROLE') == '2' ? (<h5
                  className={`font-bold text-[14px] ${
                    activePage === "product_list"
                      ? "text-[#CC9306] font-bold"
                      : "text-[#7C7C7C]"
                  } max-sm:text-[11px] font-[250] text-center ml-[32px] cursor-pointer`}
                  onClick={producrOnClick}
                >
                  Product List
                </h5>):(<div></div>)}
              </div>
            </div>
          </div>
        ) : (
          <div className="ml-[200px] flex justify-between max-md:ml-[80px] max-sm:ml-[27px] cursor-pointer">
            {sessionStorage.getItem('USER_ROLE') == '2' ? (<h5
              className={`text-[14px] ${
                activePage === "admin"
                  ? "text-[#CC9306] font-bold  "
                  : "text-[#7C7C7C]"
              } max-sm:text-[11px] font-[500] text-center`}
              onClick={HomeOnClick}
            >
              Sales Report
            </h5>):(<div></div>)}

            {sessionStorage.getItem('USER_ROLE') == '2' ? (<h5
              className={` text-[14px] ${
                activePage === "adminlistusers"
                  ? "text-[#CC9306] font-bold"
                  : "text-[#7C7C7C]"
              } max-sm:text-[11px] font-[500] text-center ml-[32px]`}
              onClick={UsersOnClick}
            >
              User Management
            </h5>):(<div></div>)}

            {sessionStorage.getItem('USER_ROLE') == '2' ? (<h5
              className={` text-[14px] ${
                activePage === "product_list"
                  ? "text-[#CC9306] font-bold"
                  : "text-[#7C7C7C]"
              } max-sm:text-[11px] font-[500] text-center ml-[32px]`}
              onClick={producrOnClick}
            >
              Product List
            </h5>):(<div></div>)}
          </div>
        )
        }
      </div>
      :<div></div>
    }

      <div
        className="absolute right-[50px] max-sm:right-[20px] bg-[#F4CF7B] w-[48px] h-[48px] rounded-[50%] flex items-center justify-center
                           max-sm:h-[40px]  max-sm:w-[40px] cursor-pointer "
        onClick={() => {
          setVisible(true);
        }}
      >
        <h3 className="font-bold pt-[8px] text-[16px] text-[#936805]">{sessionStorage.getItem("USER_NAME") ? sessionStorage.getItem("USER_NAME").charAt(0) : ""}</h3>
      </div>

      <Modal
        onCancel={() => setVisible(false)}
        footer={null}
        visible={visible}
        // style={{ top: 70, right:0, }}
        style={{
          alignSelf: "flex-start",
          marginRight: "20px",
          marginTop: "-27px",
        }}
        width={278}
        closable={false}
      >
        <div
          className="w-[214px] h-[123px] mx-[8px] my-[6px] "
          style={{ fontFamily: "Montserrat, sans-serif " }}
        >
          <div className="w-auto h-[45px]   ">
            <h5 className="font-[700] text-[16px] leading-[10.5px]">Profile</h5>
            <h6 className="font-[500] text-[14px] leading-[20.07px] mt-[10px]">
              {sessionStorage.getItem("USER_NAME")}
            </h6>
            <h6 className="font-[500] text-[14px] leading-[10.07px] mt-[10px]">
              {sessionStorage.getItem("USER_EMAIL")}
            </h6>
          </div>
          <button
            className="bg-[#F2F2F2] w-[100%] h-[37px] mt-[32px] hover:bg-[#d8d6d6] text-[#575757] font-[600] text-[14px] py-2 px-4  rounded"
            onClick={handleLogout}
          >
            Log out
          </button>

          <div className='flex justify-center mt-3 '>
            <h6 className='text-[10px] text-[#d6d6d6]'>Powerd by Logicfy</h6>
          </div>
        </div>
      </Modal>
    </div>
  </div>
</div>

  )
}

export default Navbar

