import React from 'react';
import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';
import "./Style.css";
import axios from 'axios';
import {productPdf , newproductPdf, getDistrictsPdf, getRepsPdf} from '../Commons/Urls.js'
import { useState,useEffect } from 'react';



const Pdf = () => {
  const [productDetails,setproductDetails] = useState([])
  const [totalQuantity ,settotalQuantity] = useState('')
  const [total ,settotal] = useState('')
  const [selectedMonth, setSelectedMonth] = useState('');
  const [month, setmonth] = useState('');
  const [selectedDistrict, setSelectedDistrict] = useState('');
  const [disctrictId, setDisctrictId] = useState('');
  const [selectedRep, setSelectedRep] = useState('');
  const [dirtictList, setDirtictList] = useState([]);
  const [repList, setRepList] = useState([]);
  const userRole = sessionStorage.getItem('USER_ROLE');
  const userId = sessionStorage.getItem('USER_ID');
  const [repId, setReptId] = useState(userRole == 5 ? userId : '');

    // const [productDetails,setproductDetails] = useState([])
    // const [totalQuantity ,settotalQuantity] = useState('')
    // const [total ,settotal] = useState('')

    

    // const ListAllProductDetails = async ()=>{
    //   try{
  
    //       const response = await axios.get(`${productPdf}`);
    //       if(response){
    //         console.log("response=====",response?.data?.data?.product_data)
    //         setproductDetails(response?.data?.data?.product_data);    
    //         settotalQuantity(response?.data?.data?.total_quantity)
    //         settotal(response?.data?.data?.total_price)
            
                                    
    //       }
    //   }catch(error){
    //       console.log(error)
    //   }
    // }

    // useEffect(()=>{
    //     ListAllProductDetails();
    //   },[]);
  



    //  
    const generatePDF = () => {
        const input = document.getElementById("pdf");
        const pdf = new jsPDF('p', 'mm', 'a4');
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = pdf.internal.pageSize.getHeight();


        html2canvas(input).then((canvas) => {
            const imgData = canvas.toDataURL('image/png');
            const imgProps = pdf.getImageProperties(imgData);
            const imgHeight = (imgProps.height * pdfWidth) / imgProps.width;
            let heightLeft = imgHeight;
            let position = 0;


            pdf.addImage(imgData, 'PNG', 0, position, pdfWidth, imgHeight);
            heightLeft -= pdfHeight;

            while (heightLeft >= 0) {
                position = heightLeft - imgHeight;
                pdf.addPage();
                pdf.addImage(imgData, 'PNG', 0, position, pdfWidth, imgHeight);
                heightLeft -= pdfHeight;

            }

            pdf.save(pdf.pdf);
        });
    };

 const listDistricts = async () => {
  try{
    const response = await axios.get(
      `${getDistrictsPdf}`,
      {
        headers: {
          'Content-Type': 'application/json', 
          Authorization: "Bearer " + sessionStorage.getItem('TOKEN')
        }
      }
    ).then((response)=>{
      if(response.data?.status == true){
        setDirtictList(response.data.data)
      };
    });
  }catch(error){
    console.log(error)
  }
 }

 const listReps = async () => {
  try{
    const response = await axios.post(
      getRepsPdf,
      {
        manager_id:userId
      },
      {
        headers: {
          'Content-Type': 'application/json', 
          Authorization: "Bearer " + sessionStorage.getItem('TOKEN')
        }
      }
    ).then((response)=>{
      if(response.data?.status == true){
        setRepList(response.data.data)
      };
    });
  }catch(error){
    console.log(error)
  }
 }

  useEffect(()=>{
    if(userRole == 4 || userRole == 5){
      listDistricts();
    }
    if(userRole == 4){
      listReps();
    }
  },[]);

 const listAllProductDetails = async ()=>{
      try{
        const response = await axios.post(
          `${newproductPdf}`,
          {
            month:month,
            district:disctrictId,
            rep:repId
          },
          {
            headers: {
              'Content-Type': 'application/json', 
              'Authorization': "Bearer " + sessionStorage.getItem('TOKEN')
            }
          },
        );
        if(response){
          setproductDetails(response?.data?.data?.product_data);    
          // settotalQuantity(response?.data?.data?.total_quantity)
          // settotal(response?.data?.data?.total_price)                          
        }
        
      }catch(error){
          console.log(error)
      }
    }

    useEffect(()=>{
      listAllProductDetails();
    },[month,disctrictId,repId]);




 

  const handleMonthChange = (event) => {
    const selectedOption = event.target.options[event.target.selectedIndex];
    setSelectedMonth(selectedOption.value);
    setmonth(parseInt(selectedOption.getAttribute('data-id'), 10));
  };

  const handleDistrictChange = (event) => {
    setDisctrictId(event.target.selectedOptions[0].id);
    setSelectedDistrict(event.target.value);
  };

  const handleRepChange = (event) => {
    setSelectedRep(event.target.value);
    setReptId(event.target.selectedOptions[0].id);
  };



  const getMonthlyQuantity = (item, month) => {
    // Replace this with the logic to get monthly quantity
    return item[month.toLowerCase()]?.quantity || 0;
  };

  const getMonthlyPrice = (item, month) => {
    // Replace this with the logic to get monthly price
    return item[month.toLowerCase()]?.price || 0;
  };
  
    return (
        <div >
            <body className='body-pdf px-2'>

                <div className=' relative'>
                    <div className='absolute top-0 right-5'>
                        <div className='flex items-center justify-center flex-col'>
                            <button onClick={generatePDF} style={{ marginTop: '25px' }}><span class="material-symbols-outlined max-md:text-[5px]" style={{ fontSize: "20px" }}>download</span>
                                <h6 className=' mt-[-6px] text-[red] mr-[1px] max-md:text-[10px] text-[12px]'>Download PDF</h6>
                            </button>
                        </div>
                    </div>
                </div>



             

{/* ----------------------------------------------------------------- */}



<div className='flex'>
  <div>
      <label className='label-pdf' htmlFor="month">Choose a month:</label>
      <select className='select-pdf'  style={{cursor:'pointer'}} id="month" value={selectedMonth} onChange={handleMonthChange}>
        <option value="" disabled>Select a month</option>
        <option value="January" data-id="1">January</option>
        <option value="February" data-id="2">February</option>
        <option value="March" data-id="3">March</option>
        <option value="April" data-id="4">April</option>
        <option value="May" data-id="5">May</option>
        <option value="June" data-id="6">June</option>
        <option value="July" data-id="7">July</option>
        <option value="August" data-id="8">August</option>
        <option value="September" data-id="9">September</option>
        <option value="October" data-id="10">October</option>
        <option value="November" data-id="11">November</option>
        <option value="December" data-id="12">December</option>
      </select>
      {selectedMonth && 
      <div className='flex justify-start items-center'>
      <p className='p-pdf text-left'>Selected Month: {selectedMonth}</p>
      </div>
      }
  </div>
    {(userRole == 4 || userRole == 5) && (
        <div>
          <label className='label-pdf' htmlFor="month">Choose a district:</label>
          <select className='select-pdf' style={!month ? {background : '#d6d6d6', color : '#7a7a7a'} : {cursor:'pointer'}} id="month" value={selectedDistrict} disabled={month ? false :true} onChange={handleDistrictChange}>
            <option value="" >Select a district</option>
            {
              dirtictList && dirtictList.map(district => (
                <option value={district.name} id={district.id}>{district.name}</option>
              )) 
            }
          </select>
        </div>
      )
    }
    {userRole == 4 && (
      <div>
        <label className='label-pdf' htmlFor="month">Choose a representative:</label>
        <select className='select-pdf' style={!month ? {background : '#d6d6d6',color : '#7a7a7a'} : {cursor:'pointer'}} id="month" value={selectedRep} disabled={month ? false :true} onChange={handleRepChange}>
          <option value="" >Select a representative</option>
          {
            repList && repList.map(rep => (
              <option value={rep.name} id={rep.id}>{rep.name}</option>
            ))
          }
        </select>
      </div>
    )
    }
    {/* </div> */}
       
  
</div>
<div >

{/* table---------------------- */}
 {month===''? "" :
<>
<div className="container-pdf " id="pdf">
                    <h1 className='h1-pdf'>Viventure</h1>
                  
                    <h2 className='h2-pdf'>PRODUCT WISE ANALYSIS</h2>

{productDetails.map((district) => (
  <>

<div className="location" key={district.district_id}  >
<h3 className='h3-pdf'>{district.district_name}</h3>
    <table className='table-pdf'>

    {district.product.map((monthdata) => (
  <>

  


  <tr>
    <th className='th-pdf'>Product Description</th>
    <th className='th-pdf'>Quantity</th>
    <th className='th-pdf'>Amount</th>
  </tr>
  {monthdata.monthdata.map((specificData) => (
  <>
  
  <tr>
    <td className='td-pdf'>{specificData?.items}</td>
    <td className='td-pdf'>{specificData?.monthly_quantity}</td>
    <td className='td-pdf'>{specificData?.monthly_price}</td>
  </tr>

  </>
  ))}
  
  <tr>
    <td className=''></td>
    <td className=''></td>
    <td className=''>
    <div className=" mt-[40px] mb-[30px] w-[auto]  flex justify-end items-center">
              
              <table>
                  <tr>
                      <td className="bold td-pdf text-[15px]">Total Quantity</td>
                      <td className='td-pdf'><div className='flex flex-col justify-end items-end '>
                          <h6>{monthdata.total_monthly_quantity}</h6>
                      </div></td>
                  </tr>
                  <tr>
                      <td className="bold td-pdf text-[15px]">Total Amount</td>
                      <td className='td-pdf'><div className='flex flex-col justify-end items-end '>
                          <h6>{monthdata.total_monthly_price}</h6>
                      </div></td>
                  </tr>
              </table>
          </div>

      </td>
  </tr>
                     
  </>
    ))}
</table>
<hr></hr>
</div>

</>
))}
</div>
</>
}

</div>

            </body>
        </div>
    );
};

export default Pdf
