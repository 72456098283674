import React from 'react'
import { Modal } from 'antd';

export default function ErrorModel(props) {
  return (
    <>
        <Modal onCancel = {()=> props.setVisible(false)}  footer={null}  visible={props.getVisible} width={460} top={0}>
            <div className='pt-[20px] py-[24px]'style={{fontFamily:'Montserrat, sans-serif'}}>
                <div className='w-[auto] '>
                    <h1 className='font-[600] text-[20px] leading-[24.38px] text-[#1C1C1C] mb-[24px]'>Info</h1>
                    <hr></hr>
                    <div className='w-[auto] '>
                    <h3 className='font-[300] text-[18px] leading-[24.38px] text-[#1C1C1C] mb-[24px]'>{props.message}</h3>
                        <div className='mt-[40px]  h-[49px] flex items-center relative'>
                            <div className='absolute right-0 w-[203] h-[49px]   '>
                                {/* <button className="bg-[#F6F6F6] hover:bg-[#fcfaf7] text-black font-[400] text-[12px] py-4 px-4 border border-[#EBEBEB] rounded mr-3 mt-0 h-[48px] w-[163px] max-sm:w-[100px] " onClick={() => props.setVisible(false)}>No</button> */}
                                <button className="bg-[#F4CF7B] hover:bg-[#e7ca88] text-[#7A4310] font-[600] text-[14px] py-2 px-4 border border-[#F4CF7B] w-[163px] h-[48px] rounded max-sm:w-[140px]" onClick={() => props.setVisible(false)}>OK</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    </>
  )
}
