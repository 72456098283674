import React from 'react';
import Navbar from '../Components/Navbar';
import { useState,useEffect } from 'react';
import { Modal } from 'antd';
import Select from 'react-select';
import Eye from '../Assets/images/icon.png'
import {MakeNetworkCall} from '../Commons/MakeNetworkCall';
import {postUsersUrls,getAdminsUrls,postSuperAdminUpdateUserUrls,getDeleteUsersUrls,getAllSuperAdminUserEditDetailsUrls} from '../Commons/Urls';
import ErrorModel from '../Components/ErrorModel';
import Hide from '../Assets/images/hide.png'



function Superadminhome() {

    
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);
    const [visible,setVisible ] = useState(false);
    const [edit,setEdit ] = useState(false);
    const [selectedOptions, setSelectedOptions] = useState([]);


    const [getData, setData] = useState([]);
    const [getName, setName] = useState([]);
    const [getEmail, setEmail] = useState([]);
    const [getPassword, setPassword] = useState([]);
    const [getConfirmPassword, setConfirmPassword] = useState([]);
    const [getEditName, setEditName] = useState([]);
    const [getEditEmail, setEditEmail] = useState([]);
    const [getEditId, setEditId] = useState("");
    const [getDelete,setDelete ] = useState(false);
    const [getSelectUserName, setSelectUserName] = useState("");
    const [getSelectUserId, setSelectUserId] = useState("");
    const [showPassword, setShowPassword] = useState(false);


    const [getAllowedRepresentativesCount,setAllowedRepresentativesCount ] = useState("12");
    const [getAllowedManagersCount, setAllowedManagerssCount] = useState("3");
    const [getStateHeadCount, setStateHeadCount] = useState("1");


    const [getErrorModelVisible,setErrorModelVisible ] = useState(false);
    const [getErrorModelMessage,setErrorModelMessage ] = useState("");

    const [getEditError,setEditError ] = useState(false);
    const [getEditErrorMessage,setEditErrorMessage ] = useState("");
    const [getError,setError ] = useState(false);
    const [getErrorMessage,setErrorMessage ] = useState("");



    function clearallFields(){
        setErrorMessage(false);
        setError(false);
        setEditErrorMessage(false);
        setEditError(false);
        setErrorModelMessage(false);
        setErrorModelVisible(false);
        setEditId("");
        setDelete(false);
    }

    function clearAllFieldsFromSubmitNew(){
        setName("");
        setEmail("");
        setPassword("");
        setConfirmPassword("");
        setAllowedRepresentativesCount("12");
        setAllowedManagerssCount("3");
        setStateHeadCount("1");

        setErrorMessage(false);
        setError(false);
        setEditErrorMessage(false);
        setEditError(false);
        setErrorModelMessage(false);
        setErrorModelVisible(false);

        setVisible(false);
        setEdit(false);
    }

  useEffect(() => {
    FOnPageLoad();
    const handleResize = () => { setScreenWidth(window.innerWidth); };
    window.addEventListener('resize', handleResize);
    return () => { window.removeEventListener('resize', handleResize); };
  }, []);
  const pageOnLoadCallBack = (res) => {
    console.log('=========pageOnLoadCallBack:callback:',res);
    if(res.status){
        setErrorModelVisible(false);
        const data = res.data;
        setData(data);
    }else{
        console.log('=========callback:',res);
        setErrorModelVisible(true);
        setErrorModelMessage(res.message);
    }
}
  const FOnPageLoad = () => {
    MakeNetworkCall(getAdminsUrls,'GET',{},pageOnLoadCallBack)
  }
  const handleNameChange = (e) => { setName(e.target.value) };
  const handleEmailChange = (e) => { setEmail(e.target.value) };
  const handlePasswordChange = (e) => { setPassword(e.target.value) };
  const handleConfirmPasswordChange = (e) => { setConfirmPassword(e.target.value) };


  const handleEditNameChange = (e) => { setEditName(e.target.value) };
  const handleEditEmailChange = (e) => { setEditEmail(e.target.value) };


  const handleAllowedRepresentativesCountChange = (e) => { setAllowedRepresentativesCount(e.target.value) };
  const handleManagersCountChange = (e) => { setAllowedManagerssCount(e.target.value) };
  const handleStateHeadCountChange = (e) => { setStateHeadCount(e.target.value) };


  const options = [
    { value: 'Option 1', label: 'Option 1' },
    { value: 'Option 2', label: 'Option 2' },
    { value: 'Option 3', label: 'Option 3' },
    // Add more options as needed
  ];



  const handleChange = (selectedValues) => { setSelectedOptions(selectedValues); };

  const customStyles = {
    control: (provided,state) => ({
      ...provided,
      backgroundColor: '#FAFAFA',
      border: state.isFocused ? '1px solid #E3E3E3' : '1px solid #ced4da',
      boxShadow: state.isFocused ? '0 0 0 0' : null,
      '&:hover': {
        border: state.isFocused ? '1px solid #E3E3E3' : '1px solid #ced4da'
      }
    }),
    multiValue: (provided) => ({
        ...provided,
        backgroundColor: '#FBECCA',
        border:'1px solid #A97604',
      
      }),
      multiValueLabel: (provided) => ({
        ...provided,
        color: '#C08600',
      }),
      option: (provided, state) => ({
        ...provided,
        backgroundColor: state.isFocused ? '#FBECCA' : null,
        '&:hover': {
          backgroundColor: '#FBECCA',
        },
      }),  
      placeholder: (provided) => ({
        ...provided,
        padding: '8px 5px', // Adjust the padding value as needed
      }),

      indicatorSeparator: () => ({
        display: 'none',
      }),
      dropdownIndicator: (provided) => ({
        ...provided,
        color: '#505967',
        width: '31px',
      
        
      }),
  };
  const addNewUserCallBack = (res) => {
    console.log('========addNewUserCallBack=callback:',res.status);
    if(res.status){
        clearAllFieldsFromSubmitNew();
        FOnPageLoad();
    }else{
        console.log('=========errorcallback:',res);
        setError(true);
        setErrorMessage(res.message);
    }
}
    function handleSubmit(){  
        try {
            if(getPassword == getConfirmPassword){
                setError(false);
                const obj = {
                    name:getName,
                    email:getEmail,
                    password:getPassword,
                    role:'2',
                    rep:getAllowedRepresentativesCount,
                    manager:getAllowedManagersCount,
                    statehead:getStateHeadCount,
                    myrole:sessionStorage.getItem('USER_ROLE')
                }
                MakeNetworkCall(postUsersUrls,'POST',obj,addNewUserCallBack);
            }else{
                setError(true);
                setErrorMessage("Password and Confirm password should be equal");
            }
        } catch (error) {
            // Handle errors, such as displaying an error message
            console.log('There was a problem with the API call:', error.toString());
            setError(true);
            setErrorMessage("Check the User Email may be its already created");
        }
    }
    async function setDeleteItem(item){
        setSelectUserId(item.id);
        setSelectUserName(item.name);
        setDelete(true);
    }

    const deleteUsers = async () => {
        const obj = {id: getSelectUserId};
        console.log("======obj:",obj);
        console.log("======postSalesDetailsUrl:",getDeleteUsersUrls);
        const response = await fetch(getDeleteUsersUrls, {
            method: 'POST',
            headers: {'Content-Type': 'application/json', 'Authorization': "Bearer " + sessionStorage.getItem('TOKEN')},
            body: JSON.stringify(obj)
        });
        if (!response.status) {
            throw new Error('Network response was not ok');
        } else {
            const responseData = await response.json();
            console.log('=========resp:', responseData.status);
            if(responseData.status){
                setDelete(false);
                FOnPageLoad();
            }else{
                console.log('=========resp:', responseData);
            }
        }
    }  
    const setEditUser = async (item) => {
        console.log('========item:',item);

        console.log("======Item:",item);
        const obj = {item_id: item.id};
        console.log("======obj:",obj);
        console.log("======postSalesDetailsUrl:",getAllSuperAdminUserEditDetailsUrls);
        const response = await fetch(getAllSuperAdminUserEditDetailsUrls, {
            method: 'POST',
            headers: {'Content-Type': 'application/json', 'Authorization': "Bearer " + sessionStorage.getItem('TOKEN')},
            body: JSON.stringify(obj)
        });
        if (!response.status) {
            throw new Error('Network response was not ok');
        } else {
            const responseData = await response.json();
            console.log('=========DATA:', responseData.data.user[0]);
            const user = responseData.data.user[0];
            setEditName(user.name);
            setEditEmail(user.email);
            setEditId(user.id);
            setAllowedRepresentativesCount(user.rep);
            setAllowedManagerssCount(user.manager);
            setStateHeadCount(user.statehead);
            setEdit(true);
        }
    }
    const appendTableData = () => {
        const renderedItems = [];
            // console.log("=======getData:",getData);
            if(getData.length != 0){
                console.log("=======+1:",getData.length);
                for (let i = 0; i < getData.length; i++) {
                    const item = getData[i];
                    renderedItems.push(
                        <tbody className='h-[70px] font-[500] text-[14px] leading-[19.36px] text-[#464646] max-sm:text-[12px]'  style={{fontFamily:'Montserrat, sans-serif'}}>
                            <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                            <th scope="row" className="px-6 py-4 font-medium   w-[173px]  ">
                                { screenWidth < 756 ?
                                    <div className='flex  items-center'>{item.name}</div>
                                    :
                                    <div className='flex  items-center'>
                                      <div className='  bg-[#F0F2EC] w-[32px] h-[32px] rounded-[50%] flex items-center justify-center mr-[6px]'> <h3 className='font-[500] text-[14px] mt-[6px] text-[#828282]'>{item.name.charAt(0)}</h3>
                                        </div>
                                        {item.name}
                                    </div>
                                }

                            </th>
                            <td className="px-6 py-4 text-[#050505] w-[163px] ">
                            {item.email}
                            </td>
                            <td className="px-6 py-4  text-[#050505]">
                            {item.role_name}
                            </td>
                            <td className="px-6 py-4  text-[#050505]">
                            {item.statehead}
                            </td>
                            <td className="px-6 py-4  text-[#050505]">
                            {item.manager}
                            </td>
                            <td className="px-6 py-4  text-[#050505]">
                            {item.repo}
                            </td>
                            
                    
                            <td className="px-[33px] py-4  ">
                                <div className=' w-[70px] flex justify-between max-sm:w-[60px] max-sm:ml-[5px]'>
                                <span className="material-symbols-outlined font-[300]  cursor-pointer max-sm:text-[22px] "  type="button" onClick={()=>{setEditUser(item)}} > border_color</span>
                                <span className="material-symbols-outlined font-[300]  cursor-pointer max-sm:text-[22px] " type="button" onClick={() => {setDeleteItem(item)}}> delete</span>
                        
                                </div>
                            </td>
                    
                            </tr>
                        </tbody>
                    )
                }
            }else{
                renderedItems.push(<tbody className='h-[70px] font-[500] text-[16px] leading-[19.36px] text-[#464646]'>
                    <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                        <th scope="row" className="px-6 py-4 font-medium  whitespace-nowrap ">
                        No Data Availabile
                        </th>
                    </tr>
                </tbody>)
            }

            return renderedItems;
    }
    function handleEditUserSubmit(){   
        try {
            const obj = {
                id:getEditId,
                name:getEditName,
                email:getEditEmail,
                rep:getAllowedRepresentativesCount,
                manager:getAllowedManagersCount,
                statehead:getStateHeadCount
            }
            MakeNetworkCall(postSuperAdminUpdateUserUrls,'POST',obj,addNewUserCallBack)
        } catch (error) {
            // Handle errors, such as displaying an error message
            console.log('There was a problem with the API call:', error.toString());
        }
    }


    //password hide or show
const togglePasswordVisible=() =>{
    setShowPassword(!showPassword);
 };

  return (
    <div className=''>
       
        <Navbar activePage="superadmin" />
        <div className='bg-[#f8f8f9] px-[48px]  pb-[80px] min-h-[100vh]  pt-[86px] max-sm:px-[20px]  ' style={{fontFamily:'Montserrat, sans-serif'}} >
            <div className='w-auto h-auto '>
                <div className=' flex justify-between'> <h1 className='font-[600] text-[24px] leading-[29.26px] text-[#343434] max-sm:text-[20px]'>Manage Users</h1>
                    {screenWidth < 756 ?
                        <button className="bg-[#F4CF7B] hover:bg-[#e7ca88] text-[#7A4310] font-[600]   px-2.5 py-1.5 text-[12px] border border-[#F4CF7B] rounded  "
                        onClick={()=>{clearAllFieldsFromSubmitNew();setVisible(true)}}>
                        <span className="material-symbols-outlined text-[18px]">person_add</span>
                        </button>
                    :
                        <button className="flex items-center bg-[#F4CF7B] hover:bg-[#dbba6c] h-[48px] w-[179px] text-[#7A4310] font-[600] text-[14px] py-2 px-4  rounded"
                        onClick={()=>{clearAllFieldsFromSubmitNew();setVisible(true)}}>
                            <span className="material-symbols-outlined mr-1  text-[20px]">person_add</span>Add New User</button>
                    }
                </div>
                <div className='mt-[24px]'>
                    <div className= ' bottom-div-with-hidden-scrollbar h-auto   overflow-x-auto' >
                    <table className="w-full text-sm text-left rtl:text-right">
                        <thead className="bg-[#FFFAEE] text-[#393939] text-[14px] font-[500] h-[67px] leading-[16.94px] max-sm:text-[12px]"style={{whiteSpace:'nowrap'}}>
                            <tr>
                            <th scope="col" className="px-6 py-3">
                                User Name
                            </th>
                            <th scope="col" className="px-6 py-3">
                                User E-mail
                            </th>
                            <th scope="col" className="px-6 py-3">
                                Role
                            </th>
                            <th scope="col" className="px-6 py-3">
                                Total State Head Count
                            </th>
                            <th scope="col" className="px-6 py-3">
                                Total Managers Count
                            </th>
                            <th scope="col" className="px-6 py-3">
                                Total Representative Count
                            </th>
                            <th scope="col" className="px-[45px] py-3">
                            Action
                            </th>
                            
                            </tr>
                        </thead>
                        {appendTableData()}
                    </table>
                </div>
                </div>
            </div>
       
            <Modal onCancel = {()=> setVisible(false)}  footer={null}  visible={visible} width={460} top={0}>
                <div className='pt-[20px] py-[24px]'style={{fontFamily:'Montserrat, sans-serif'}}>
                    <div className='w-[auto] '>
                        <h1 className='font-[600] text-[20px] leading-[24.38px] text-[#1C1C1C] mb-[24px]'>Add New User</h1>
                        <hr></hr>

                        {/* --------form------------ */}
                        <div className='w-[auto] '>
                        <form>
                            <div className="mb-4 mt-6">
                                <label className="block text-[#32324D] text-[12px] font-[400] leading-[16px] mb-2" >
                                    User Name
                                </label>
                                <input className=" bg-[#FAFAFA] border border-[#E3E3E3] rounded w-full h-[40px] py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="username" type="text" placeholder='Eg: John Doe'  value={getName} onChange={handleNameChange}/>
                            </div>
                            <div className="mb-4">
                                <label className="block text-[#32324D] text-[12px] font-[400] leading-[16px] mb-2" >
                                    User E-mail
                                </label>
                                <input className=" bg-[#FAFAFA] border border-[#E3E3E3] rounded w-full h-[40px] py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="username" type="text" placeholder='Eg: Johndoe@gmail.com'  value={getEmail} onChange={handleEmailChange}/>
                            </div>
                            <div className="mb-4">
                                <label className="block text-[#32324D] text-[12px] font-[400] leading-[16px] mb-2" >
                                    Password
                                </label>
                                <input className=" bg-[#FAFAFA] border border-[#E3E3E3] rounded w-full h-[40px] py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="username" type="password" value={getPassword} onChange={handlePasswordChange} />
                            </div>
                            <div className="mb-4 relative">
                                <label className="block text-[#32324D] text-[12px] font-[400] leading-[16px] mb-2" >
                                    Re-enter Password
                                </label>
                                <div className='relative   h-[40px]'> 
                                <input className=" bg-[#FAFAFA]  border border-[#E3E3E3] rounded w-full h-[40px] py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="username" type={showPassword?"test":"password"} value={getConfirmPassword} onChange={handleConfirmPasswordChange} />
                                <button onClick={togglePasswordVisible}>
                               {showPassword?<img className='absolute w-[16px] right-3 top-3' src={Hide} alt=''/> :<img className='absolute w-[16px] right-3 top-3' src={Eye} alt=''/>}
                              </button>
                            </div>
                            </div>
                            <div className="mb-4">
                                <label className="block text-[#32324D] text-[12px] font-[400] leading-[16px] mb-2" >
                                    Allowed Representatives Count
                                </label>
                                <input className=" bg-[#FAFAFA] border border-[#E3E3E3] rounded w-full h-[40px] py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="username" type="number" placeholder='Eg: 12'  value={getAllowedRepresentativesCount} onChange={handleAllowedRepresentativesCountChange}/>
                            </div>
                            <div className="mb-4">
                                <label className="block text-[#32324D] text-[12px] font-[400] leading-[16px] mb-2" >
                                    Allowed Managers Count
                                </label>
                                <input className=" bg-[#FAFAFA] border border-[#E3E3E3] rounded w-full h-[40px] py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="username" type="number" placeholder='Eg: 3'  value={getAllowedManagersCount} onChange={handleManagersCountChange}/>
                            </div>
                            <div className="mb-4">
                                <label className="block text-[#32324D] text-[12px] font-[400] leading-[16px] mb-2" >
                                    Allowed State Head Count
                                </label>
                                <input className=" bg-[#FAFAFA] border border-[#E3E3E3] rounded w-full h-[40px] py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="username" type="number" placeholder='Eg: 1'  value={getStateHeadCount} onChange={handleStateHeadCountChange}/>
                            </div>
                        </form>
                        {getError ? <p  className="relative self-center text-center text-[medium] not-italic text-[#dc3545]">{getErrorMessage}</p> : <div></div>}
                        <div className='mt-[40px]  h-[49px] flex items-center relative'>
                                <div className='absolute right-0 w-[203] h-[49px]   '>
                                    <button className="bg-[#ffff] absolute right-[105px] hover:bg-[#fcfaf7] text-black font-[400] text-[14px]  px-4 border border-[#EBEBEB] rounded mr-3 mt-0 h-[49px] w-[98px] "type={'cancel'} onClick={() => {
                                        clearallFields();
                                        setVisible(false);
                                        }}>Cancel</button>
                                    <button className="bg-[#F4CF7B] absolute right-0 hover:bg-[#e7ca88] text-[#7A4310] font-[600] text-[14px] border border-[#F4CF7B] w-[105px] h-[48px] rounded m-0  " type={'submit'} onClick={() => {handleSubmit()}}>Save</button>
                                </div>
                            </div>
                    </div>
                </div>
                </div>
            </Modal>
            {/*-----table edit-----modal  */}
            <Modal onCancel = {()=> setEdit(false)}  footer={null}  visible={edit} width={460} top={0}>
                <div className='pt-[20px] py-[24px]'style={{fontFamily:'Montserrat, sans-serif'}}>
                    <div className='w-[auto] '>
                        <h1 className='font-[600] text-[20px] leading-[24.38px] text-[#1C1C1C] mb-[24px]'>Edit User</h1>
                        <hr></hr>
                        <div className='w-[auto] '>
                        <form>
                            <div className="mb-4 mt-6">
                                <label className="block text-[#32324D] text-[12px] font-[400] leading-[16px] mb-2" >
                                    User Name
                                </label>
                                <input className=" bg-[#FAFAFA] border border-[#E3E3E3] rounded w-full h-[40px] py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="username" type="text" placeholder='Eg: John Doe'  value={getEditName} onChange={handleEditNameChange}/>
                            </div>
                            <div className="mb-4">
                                <label className="block text-[#32324D] text-[12px] font-[400] leading-[16px] mb-2" >
                                    User E-mail
                                </label>
                                <input className=" bg-[#FAFAFA] border border-[#E3E3E3] rounded w-full h-[40px] py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"  id="username" type="email" placeholder='Eg: Johndoe@gmail.com'  value={getEditEmail} onChange={handleEditEmailChange}/>
                            </div>
                            <div className="mb-4">
                                <label className="block text-[#32324D] text-[12px] font-[400] leading-[16px] mb-2" >
                                    Allowed Representatives Count
                                </label>
                                <input className=" bg-[#FAFAFA] border border-[#E3E3E3] rounded w-full h-[40px] py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="username" type="number" placeholder='Eg: 12'  value={getAllowedRepresentativesCount} onChange={handleAllowedRepresentativesCountChange}/>
                            </div>
                            <div className="mb-4">
                                <label className="block text-[#32324D] text-[12px] font-[400] leading-[16px] mb-2" >
                                    Allowed Managers Count
                                </label>
                                <input className=" bg-[#FAFAFA] border border-[#E3E3E3] rounded w-full h-[40px] py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="username" type="number" placeholder='Eg: 3'  value={getAllowedManagersCount} onChange={handleManagersCountChange}/>
                            </div>
                            <div className="mb-4">
                                <label className="block text-[#32324D] text-[12px] font-[400] leading-[16px] mb-2" >
                                    Allowed State Head Count
                                </label>
                                <input className=" bg-[#FAFAFA] border border-[#E3E3E3] rounded w-full h-[40px] py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="username" type="number" placeholder='Eg: 1'  value={getStateHeadCount} onChange={handleStateHeadCountChange}/>
                            </div>
                        </form>
                        {getEditError ? <p  className="relative self-center text-center text-[medium] not-italic text-[#dc3545]">{getEditErrorMessage}</p> : <div></div>}
                        <div className='mt-[40px]  h-[49px] flex items-center relative'>
                                <div className='absolute right-0 w-[203] h-[49px]   '>
                                    <button className="bg-[#ffff] absolute right-[133px] hover:bg-[#fcfaf7] text-black font-[400] text-[14px]  px-4 border border-[#EBEBEB] rounded mr-3 mt-0 h-[49px] w-[98px]  " onClick={() => {
                                        clearallFields();
                                        setEdit(false);
                                        }}>Cancel</button>
                                    <button className="bg-[#F4CF7B] absolute right-0 hover:bg-[#e7ca88] text-[#7A4310] font-[600] text-[14px] border border-[#F4CF7B] w-[137px] h-[48px] px-3 rounded m-0 " onClick={() => {handleEditUserSubmit()}}>Save Changes</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
            {/* User Delete Model */}
            <Modal onCancel = {()=> setDelete(false)}  footer={null}  visible={getDelete} width={460} top={0}>
                <div className='pt-[20px] py-[24px]'style={{fontFamily:'Montserrat, sans-serif'}}>
                    <div className='w-[auto] '>
                        <h1 className='font-[600] text-[20px] leading-[24.38px] text-[#1C1C1C] mb-[24px]'>Delete User</h1>
                        <hr></hr>
                        <div className='w-[auto] '>
                        <h3 className='font-[300] text-[18px] leading-[24.38px] text-[#1C1C1C] mb-[24px]'>Are you sure want to delete {getSelectUserName} user</h3>
                            <div className='mt-[40px]  h-[49px] flex items-center relative'>
                                <div className='absolute right-0 w-[203] h-[49px]   '>
                                    <button className="bg-[#ffff] absolute right-[105px] hover:bg-[#fcfaf7] text-black font-[400] text-[14px]  px-4 border border-[#EBEBEB] rounded mr-3 mt-0 h-[49px] w-[98px] ">No</button>
                                    <button className="bg-[#F4CF7B] absolute right-0 hover:bg-[#e7ca88] text-[#7A4310] font-[600] text-[14px] border border-[#F4CF7B] w-[105px] h-[48px] rounded m-0" onClick={() => deleteUsers()}>Yes</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>

            <ErrorModel
          setVisible={setErrorModelVisible}
          getVisible={getErrorModelVisible}
          message={getErrorModelMessage}/>
        </div>
    </div>
  )
}

export default Superadminhome
