import React, {useState,useEffect} from 'react';
import Sidebar from '../Components/Sidebar'
import Navbar from '../Components/Navbar'
import '../Assets/css/App.css';
import { Container } from "reactstrap"
import TableData from '../Components/TableData';
import paginationFactory from "react-bootstrap-table2-paginator"
import filterFactory,{textFilter} from "react-bootstrap-table2-filter"
import { getStateHeadUrls,getDistrictsUrls,getAllStateHeadsUrls,getAllManagersUrls,getUsersListUrls,postUsersUrls,getUsersDetailsUrls,postUpdateUserUrls,getDeleteUsersUrls } from '../Commons/Urls';
import {Modal} from "antd";
import AddNewUser from '../Components/AddNewUser';
import axios from 'axios';
import EditUser from '../Components/EditUser';
import Eye from '../Assets/images/icon.png'
import Select from 'react-select';
import {MakeNetworkCall} from '../Commons/MakeNetworkCall';
import ErrorModel from '../Components/ErrorModel';
import Hide from '../Assets/images/hide.png';



const LOG_TAG = "UserManagement";
export default function UserManagement() {
    const [getTableColumn, setTableColumn] = useState([]);
    const [getData, setData] = useState([]);
    const [getDataCount, setDataCount] = useState([]);
    const [getAddNewUserModel, setAddNewUserModel] = useState(false);
    const [getEditUserModel, setEditUserModel] = useState(false);
    const [getEditUserModelIndex, setEditUserModelIndex] = useState("0");

    const [getStateHeadCount, setStateHeadCount] = useState("0");
    const [getManagerCount, setManagerCount] = useState("0");
    const [getRepresentativeCount, setRepresentativeCount] = useState("0");
    const [getDistrictsList, setDistrictsList] = useState([]);
    const [getStateHeadsList, setStateHeadsList] = useState([]);
    const [getManagersList, setManagersList] = useState([]);
    const [getRoleList, setRoleList] = useState([]);

    const [getLocationEnable, setLocationEnable] = useState('false');
    const [getStateHeadEnable, setStateHeadEnable] = useState('false');
    const [getManagersEnable, setManagersEnable] = useState('false');
    const [getRole, setRole] = useState("");
    const [getLocation, setLocation] = useState("");

    const [getError, setError] = useState(false);
    const [getErrorMessage, setErrorMessage] = useState("");
    const [getEditError, setEditError] = useState(false);
    const [getEditErrorMessage, setEditErrorMessage] = useState("");


    const [getManagers, setManagers] = useState("");

    const [getDelete,setDelete ] = useState(false);
    const [getSelectUserName, setSelectUserName] = useState("");
    const [getSelectUserId, setSelectUserId] = useState("");

    const [getId, setId] = useState("");
    const [getName, setName] = useState("");
    const [getEmail, setEmail] = useState("");
    const [getPassword, setPassword] = useState("");
    const [getConfirmPassword, setConfirmPassword] = useState("");



    const [getNameEnableError, setNameEnableError] = useState(false);
    const [getEmailEnableError, setEmailEnableError] = useState(false);
    const [getPasswordEnableError, setPasswordEnableError] = useState(false);
    const [getConfirmPasswordEnableError, setConfirmPasswordEnableError] = useState(false);

    const [getNameErrorMessage, setNameErrorMessage] = useState("");
    const [getEmailErrorMessage, setEmailErrorMessage] = useState("");
    const [getPasswordErrorMessage, setPasswordErrorMessage] = useState("");
    const [getConfirmPasswordErrorMessage, setConfirmPasswordErrorMessage] = useState("");


    const [getEditName, setEditName] = useState("");
    const [getEditEmail, setEditEmail] = useState("");
    const [getEditLocationEnable, setEditLocationEnable] = useState('false');
    const [getEditStateHeadEnable, setEditStateHeadEnable] = useState('false');
    const [getEditManagersEnable, setEditManagersEnable] = useState('false');

    const [getEditManagers, setEditManagers] = useState("");
    const [getEditRole, setEditRole] = useState("");
    const [getEditLocation, setEditLocation] = useState("");

    const [visible,setVisible ] = useState(false);
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [getEditLocationOptions, setEditLocationOptions] = useState([]);

    const [screenWidth, setScreenWidth] = useState(window.innerWidth);


    const [getErrorModelVisible,setErrorModelVisible ] = useState(false);
    const [getErrorModelMessage,setErrorModelMessage ] = useState("");


    const [isLoading, setIsLoading] = useState(false);
    const [edit_Loading, setEdit_Loading] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [getRoleEnableError, setRoleEnableError] = useState(false);
    const [getRoleEnableErrorMessage, setRoleEnableErrorMessage] = useState("");


    const [loader ,setLoader] =useState(false);

    useEffect(() => {
        FOnPageLoad();
        setTableColumn([
            {dataField:"name",text:"Name",sort:"true",filter:textFilter()},
            {dataField:"email",text:"Email",sort:"true",filter:textFilter()},
            {dataField:"role_name",text:"Role",sort:"true",filter:textFilter()},
            {dataField:"created_at",text:"Created On",sort:"true",filter:textFilter()}
        ]);
        const handleResize = () => { setScreenWidth(window.innerWidth); };

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    
    const handleChange = (e) => {
        console.log("===========e:",e);
        // let selected = Array.from( e, (option) => option.value);
        // console.log("===========selected:",selected);
        // setSelectedOptions(selected);
        setSelectedOptions(e);
    };
 console.log("getDistrictsList=============",getDistrictsList)

    const handleManagersChange = (e) => { setManagers(e.target.value) };
    
    const customStyles = {
        control: (provided,state) => ({
          ...provided,
          backgroundColor: '#FAFAFA',
          border: state.isFocused ? '1px solid #E3E3E3' : '1px solid #ced4da',
          boxShadow: state.isFocused ? '0 0 0 0' : null,
          '&:hover': {
            border: state.isFocused ? '1px solid #E3E3E3' : '1px solid #ced4da'
          }
        }),
        multiValue: (provided) => ({
            ...provided,
            backgroundColor: '#FBECCA',
            border:'1px solid #A97604',
          
          }),
          multiValueLabel: (provided) => ({
            ...provided,
            color: '#C08600',
          }),
          option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isFocused ? '#FBECCA' : null,
            '&:hover': {
              backgroundColor: '#FBECCA',
            },
          }),  
          placeholder: (provided) => ({
            ...provided,
            padding: '8px 5px', // Adjust the padding value as needed
          }),
    
          indicatorSeparator: () => ({
            display: 'none',
          }),
          dropdownIndicator: (provided) => ({
            ...provided,
            color: '#505967',
            width: '31px',
          
            
          }),
    };
      
    const handleRoleChange = (e) => { 
        setRole(e.target.value);
        console.log('=========e.target.value:',e.target.value);
        if(e.target.value == '3'){
            setLocationEnable("false");
            setManagersEnable('false');
        }else if(e.target.value == '4'){
            setLocationEnable("true");
            setManagersEnable('false');
        }else if(e.target.value == '5'){
            setLocationEnable("false");
            setManagersEnable('true');
        }else{
            setLocationEnable("false");
            setManagersEnable('false');
        }

    };

    const handleLocationChange = (e) => { 
        // setLocation(e.target.value) 
        let selected = Array.from( e.target.selectedOptions, (option) => option.value);
        console.log("===========selected:",selected);
        setLocation(selected);
    };

    const [edit,setEdit ] = useState(false);

    const onPageLOadCallBack = (res) => {
        console.log('=========callback:',res);
        setLoader(false);
        if(res.status){
            const data = res.data;
            setErrorModelVisible(false);
            setData(data.final);
            setStateHeadCount(data.statehead_count);
            setManagerCount(data.manager_count);
            setRepresentativeCount(data.rep_count);
            setManagersList(data.managers);
            setRoleList(data.roles);
            setDistrictsList(data.districts);
        }else{
            console.log('=========callback:',res);
            setErrorModelVisible(true);
            setErrorModelMessage("Error Occured While Loading Data, Logout Once and Login again..!");
        }
    }

    const  FOnPageLoad = async () => {
        MakeNetworkCall(getUsersListUrls,"GET",{} ,onPageLOadCallBack);
    }

    function getDistricts(){
        axios.get(getDistrictsUrls)
        .then(response => {
            var data = response.data;
            if(data.status){
                setDistrictsList({id:0,name:'Select Payment Status'});
                setDistrictsList(data.data);
            }else{
                console.error('Error fetching data:');
            }
        })
        .catch(error => { 
            console.error('Error fetching data:', error); 
        });
    }

    function getAllStateHeads(){
        axios.get(getAllStateHeadsUrls)
        .then(response => {
            var data = response.data;
            if(data.status){
                setStateHeadsList({id:0,name:'Select State Head'});
                setStateHeadsList(data.data);
            }else{
                console.error('Error fetching data:');
            }
        })
        .catch(error => { 
            console.error('Error fetching data:', error); 
        });
    }

    function getAllManangers(){
        axios.get(getAllManagersUrls)
        .then(response => {
            var data = response.data;
            if(data.status){
                setManagersList({id:0,name:'Select State Head'});
                setManagersList(data.data);
            }else{
                console.error('Error fetching data:');
            }
        })
        .catch(error => { 
            console.error('Error fetching data:', error); 
        });
    }

    const setEditUser = (params,index) => { setEditUserModel(params); setEditUserModelIndex(index); }

    const setReloadComponent = () => { 
        console.log("Reload");
        FOnPageLoad(); 
    }

    const renderTableData = () => {
        const renderedItems = [];
        console.log("=======getData:",getData);
        if(getData.length != 0){
            //console.log("=======+1:");
            for (let i = 0; i < getData.length; i++) {
                const item = getData[i];
                renderedItems.push(
                    <tbody className='h-[70px] font-[500] text-[14px] leading-[19.36px] text-[#464646] max-sm:text-[12px]'  style={{fontFamily:'Montserrat, sans-serif'}}>
                        <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                            <th scope="row" className="px-6 py-4 font-medium   w-[173px]  ">
                                {
                                    screenWidth < 756 ? <div className='flex  items-center'> {item.name} </div> : <div className='flex  items-center'> <div className='  bg-[#d4e7af] w-[32px] h-[32px]  rounded-[50%] flex items-center justify-center mr-[6px]'> <h3 className='font-[500] text-[14px]   text-[#828282]' >{item.name.charAt(0)}</h3> </div>  {item.name}  </div>
                                }
                            </th>
                            <td className="px-6 py-4 text-[#050505] w-[163px] "> {item.email}  </td>
                            <td className="px-6 py-4  text-[#050505]"> {item.role_name} </td>
                            <td className="px-[33px] py-4  "> 
                                <div className=' w-[70px] flex justify-between max-sm:w-[60px] max-sm:ml-[5px]'>
                                    <span className="material-symbols-outlined font-[300]  cursor-pointer max-sm:text-[22px] "  type="button" onClick={()=>{setEdit(true)}} > border_color</span>
                                    <span className="material-symbols-outlined font-[300]  cursor-pointer max-sm:text-[22px] " type="button"> delete</span>
                                </div>    
                            </td>
                        </tr>
                    </tbody>
                );
            }
        }
        
        {/* else{
            renderedItems.push(<tbody className='h-[70px] font-[500] text-[16px] leading-[19.36px] text-[#464646]'>
                <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                    <th scope="row" className="px-6 py-4 font-medium  whitespace-nowrap ">
                    No Data Availabile
                    </th>
                </tr>
            </tbody>)
        } */}
    }

    const setEditItem = (item) => {
        console.log("=======item:",item);
        clearAllFields();
        setId(item.id);
        const obj = {id:item.id};
        MakeNetworkCall(getUsersDetailsUrls,'POST',obj,getUserDetailsCallBack);
    }

    const getUserDetailsCallBack = (res) => {
        console.log("=======getUserDetailsCallBack:res:",res);
        setId(res.data.user.id);
        setEditName(res.data.user.name);
        setEditEmail(res.data.user.email);
        setEditRole(res.data.user.role);
        setEdit_Loading(false);

        if(res.data.user.role == '3'){
            setEditLocationEnable("false");
            setEditManagersEnable('false');
        }else if(res.data.user.role == '4'){
            setEditLocationEnable("true");
            setEditManagersEnable('false');
            const array = JSON.parse(res.data.user.location);
            let pushArray = [];
            res.data.districts.map((data,index) => {
                for (let index = 0; index < array.length; index++) {
                    const element = array[index];
                    if(element == data.value){
                        pushArray.push(data);
                    }
                }
            });
            setEditLocation(pushArray);
        }else if(res.data.user.role == '5'){
            setEditLocationEnable("false");
            setEditManagersEnable('true');
            setEditManagers(res.data.user.report_to);
        }else{
            setEditLocationEnable("false");
        }
        setEdit(true);
    }

    // setNameEnableError(false);
    // setEmailEnableError(false);
    // setPasswordEnableError(false);
    // setConfirmPasswordEnableError(false);
    // setEditName(false);
    // setEditEmail(false);
    // setEditLocationEnable(false);
    // setEditStateHeadEnable(false);
    // setEditManagersEnable(false);

    async function setDeleteItem(item){
        setSelectUserId(item.id);
        setSelectUserName(item.name);
        setDelete(true);
    }

    const deleteUsers = async () => {
        const obj = {id: getSelectUserId};
        console.log("======obj:",obj);
        console.log("======postSalesDetailsUrl:",getDeleteUsersUrls);
        const response = await fetch(getDeleteUsersUrls, {
            method: 'POST',
            headers: {'Content-Type': 'application/json', 'Authorization': "Bearer " + sessionStorage.getItem('TOKEN')},
            body: JSON.stringify(obj)
        });
        if (!response.status) {
            throw new Error('Network response was not ok');
        } else {
            const responseData = await response.json();
            console.log('=========resp:', responseData.status);
            if(responseData.status){
                setDelete(false);
                FOnPageLoad();
            }else{
                console.log('=========resp:', responseData);
            }
        }
    }  

    const appendTableData = () => {
        const renderedItems = [];
        // console.log("=======getData:",getData);
        if(getData.length != 0){
            //console.log("=======+1:",getData.length);
            for (let i = 0; i < getData.length; i++) {
                const item = getData[i];
                renderedItems.push(
                    <tbody className='h-[70px] font-[500] text-[14px] leading-[19.36px] text-[#464646] max-sm:text-[12px]'  style={{fontFamily:'Montserrat, sans-serif'}}>
                        <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                            <th scope="row" className="px-6 py-4 font-medium   w-[173px]  ">
                                {
                                    screenWidth < 756 ? <div className='flex  items-center'> {item.name} </div> : <div className='flex  items-center'> <div className='  bg-[#F0F2EC] w-[32px] h-[32px] rounded-[50%] flex items-center justify-center mr-[6px]'> <h3 className='font-[500] text-[14px] mt-[6px] text-[#828282]'>{item.name.charAt(0)}</h3> </div>  {item.name}  </div>
                                }
                            </th>
                            <td className="px-6 py-4 text-[#050505] w-[163px] ">{item.email} </td>
                            <td className="px-6 py-4  text-[#050505]"> {item.role_name} </td>
                            <td className="px-[33px] py-4  ">
                                <div className=' w-[70px] flex justify-between max-sm:w-[60px] max-sm:ml-[5px]'>
                                    <span className="material-symbols-outlined font-[300]  cursor-pointer max-sm:text-[22px] "  type="button" onClick={()=>{setEditItem(item)}} > border_color</span>
                                    <span className="material-symbols-outlined font-[300]  cursor-pointer max-sm:text-[22px] " type="button" onClick={()=>{setDeleteItem(item)}} > delete</span>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                )
            }
        }else{
            renderedItems.push(<tbody className='h-[70px] font-[500] text-[16px] leading-[19.36px] text-[#464646]'>
                <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                    <th scope="row" className="px-6 py-4 font-medium  whitespace-nowrap ">
                    No Data Availabile
                    </th>
                </tr>
            </tbody>)
        }

        return renderedItems;
    }

    function clearAllFields() {
        setEmailEnableError(false);
        setName("");
        setEmail("");
        setPassword("");
        setConfirmPassword("");
        setLocation("");
        setRole(0);
        setLocation(0);
        setManagers(0);
        setSelectedOptions([]);

        setLocationEnable("false");
        setManagersEnable('false');
    }

    const addNewUserCallBack = (res) => {
        console.log('=========addNewUserCallBack:callback:',res);
        console.log('=========addNewUserCallBack:callback:status:',res.status);
        if(res.status){
            setError(false);
            setVisible(false);
            setEdit(false);
            clearAllFields();
            FOnPageLoad();
        }else{
            console.log('=========error:',res.message);
            setError(true);
            setErrorMessage(res.message);
            // setErrorMessage("Email already availabile, recheck or provide another email address...!");
        }
    }

    const editUserCallBack = (res) => {
        console.log('=========callback:',res);
        if(res.status){
            setEdit_Loading(false);  
            setEditError(false);
            setEditError(false);
            setVisible(false);
            setEdit(false);
            clearAllFields();
            FOnPageLoad();
        }else{
            console.log('=========error:',res);
            setEdit_Loading(false);  
            setEditError(true);
            setEditErrorMessage("The email must be a valid email address");
        }
    }

    const handleNameChange = (e) => { setName(e.target.value) };
    const handleEmailChange = (e) => { setEmail(e.target.value) };
    const handlePasswordChange = (e) => { setPassword(e.target.value) };
    const handleConfirmPasswordChange = (e) => { setConfirmPassword(e.target.value) };

    const handleEditNameChange = (e) => { setEditName(e.target.value) };
    const handleEditEmailChange = (e) => { setEditEmail(e.target.value) };
    const handleEditManagersChange = (e) => { setEditManagers(e.target.value) };


      
    const handleEditRoleChange = (e) => { 
        setEditRole(e.target.value);
        console.log('=========e.target.value:',e.target.value);
        if(e.target.value == '3'){
            setEditLocationEnable("false");
            setEditManagersEnable('false');
        }else if(e.target.value == '4'){
            setEditLocationEnable("true");
            setEditManagersEnable('false');
        }else if(e.target.value == '5'){
            setEditLocationEnable("false");
            setEditManagersEnable('true');
        }else{
            setEditLocationEnable("false");
        }

    };

    const handleEditLocationChange = (e) => { 
        // setLocation(e.target.value) 
        // let selected = Array.from( e.target.selectedOptions, (option) => option.value);
        // console.log("===========selected:",selected);
        setEditLocation(e);
    };

    const handleNewSubmit = async () => {
        try{
            setError(false);
            console.log("============getRole:",getRole);
            console.log("============postUsersUrls:",postUsersUrls);
            var params = "";
            setRoleEnableError(false);
            if(getRole == "0"){
                setRoleEnableError(true);
                setRoleEnableErrorMessage('Select Role');
            }else if(getRole == '3' ){
                if(getStateHeadCount < 1){
                    if(getName.length == 0){
                        setError(false);
                        setNameEnableError(true);
                        setEmailEnableError(false);
                        setPasswordEnableError(false);
                        setConfirmPasswordEnableError(false);
                        setNameErrorMessage("Enter Name");
                    }else if(getEmail.length == 0){
                        setError(false);
                        setEmailEnableError(true);
                        setNameEnableError(false);
                        setPasswordEnableError(false);
                        setConfirmPasswordEnableError(false);
                        setEmailErrorMessage("Enter Email");
                    }else if(getPassword.length == 0){
                        setError(false);
                        setPasswordEnableError(true);
                        setNameEnableError(false);
                        setEmailEnableError(false);
                        setConfirmPasswordEnableError(false);
                        setPasswordErrorMessage('Enter Password');
                    }else if(getConfirmPassword.length == 0){
                        setError(false);
                        setConfirmPasswordEnableError(true);
                        setNameEnableError(false);
                        setEmailEnableError(false);
                        setPasswordEnableError(false);
                        setConfirmPasswordErrorMessage("Enter Confirm Password");
                    }else if(getPassword != getConfirmPassword){
                        setError(false);
                        setEmailEnableError(false);
                        setPasswordEnableError(true);
                        setPasswordErrorMessage('Password and confirm password must be equal');
                    }else if(getRole.length == 0 || getRole == 'none'){
                        setError(true);
                        setErrorMessage('Select Role');
                    }
                    else{
                        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                        if(emailRegex.test(getEmail)){
                            setError(false);
                            let selected = [];
                            selected = Array.from( selectedOptions, (option) => option.value);
                            console.log("===========selected:",selected);
                            params = { 
                                name: getName, 
                                email: getEmail, 
                                password: getPassword, 
                                role: sessionStorage.getItem('USER_ROLE') == '1' ? '2' :  getRole,
                                location:selected,
                                report_to:getRole != 5 ?  sessionStorage.getItem('USER_ID') : getManagers,
                                author:sessionStorage.getItem('USER_ID')
                            }; 
                            console.log("=======Params:",params);
                            MakeNetworkCall(postUsersUrls,'POST',params,addNewUserCallBack);
                        }else{
                            setEmailEnableError(true);
                            setEmailErrorMessage("Enter Valid Email");
                        }
                    }
                }else{
                    clearAllFields();
                    setVisible(false);
                    setErrorModelVisible(true);
                    setErrorModelMessage('State Head limit is restricted to 1');
                }
            }else if(getRole == '4'){
                if(getManagerCount < 3){
                    if(getName.length == 0){
                        setError(false);
                        setNameEnableError(true);
                        setEmailEnableError(false);
                        setPasswordEnableError(false);
                        setConfirmPasswordEnableError(false);
                        setNameErrorMessage("Enter Name");
                    }else if(getEmail.length == 0){
                        setError(false);
                        setEmailEnableError(true);
                        setNameEnableError(false);
                        setPasswordEnableError(false);
                        setConfirmPasswordEnableError(false);
                        setEmailErrorMessage("Enter Email");
                    }else if(getPassword.length == 0){
                        setError(false);
                        setPasswordEnableError(true);
                        setNameEnableError(false);
                        setEmailEnableError(false);
                        setConfirmPasswordEnableError(false);
                        setPasswordErrorMessage('Enter Password');
                    }else if(getConfirmPassword.length == 0){
                        setError(false);
                        setConfirmPasswordEnableError(true);
                        setNameEnableError(false);
                        setEmailEnableError(false);
                        setPasswordEnableError(false);
                        setConfirmPasswordErrorMessage("Enter Confirm Password");
                    }else if(getPassword != getConfirmPassword){
                        setError(false);
                        setPasswordEnableError(true);
                        setPasswordErrorMessage('Password and confirm password must be equal');
                    }else if(getRole.length == 0 ||  getRole == 'none'){
                        setError(true);
                        setErrorMessage('Select Role');
                    }else{
                        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                        if(emailRegex.test(getEmail)){
                            setError(false);
                            let selected = [];
                            selected = Array.from( selectedOptions, (option) => option.value);
                            console.log("===========selected:",selected);
                            params = { 
                                name: getName, 
                                email: getEmail, 
                                password: getPassword, 
                                role: sessionStorage.getItem('USER_ROLE') == '1' ? '2' :  getRole,
                                location:selected,
                                report_to:getRole != 5 ?  sessionStorage.getItem('USER_ID') : getManagers,
                                author:sessionStorage.getItem('USER_ID')
                            }; 
                            console.log("=======Params:",params);
                            MakeNetworkCall(postUsersUrls,'POST',params,addNewUserCallBack);
                        }else{
                                setEmailEnableError(true);
                                setEmailErrorMessage("Enter Valid Email");
                        }
                    }
                }else{
                    clearAllFields();
                    setVisible(false);
                    setErrorModelVisible(true);
                    setErrorModelMessage('Manager limit is restricted to 3');
                }
            }else if(getRole == '5'){
                if( getRepresentativeCount < 12){
                    if(getName.length == 0){
                        setError(false);
                        setNameEnableError(true);
                        setEmailEnableError(false);
                        setPasswordEnableError(false);
                        setConfirmPasswordEnableError(false);
                        setNameErrorMessage("Enter Name");
                    }else if(getEmail.length == 0){
                        setError(false);
                        setEmailEnableError(true);
                        setNameEnableError(false);
                        setPasswordEnableError(false);
                        setConfirmPasswordEnableError(false);
                        setEmailErrorMessage("Enter Email");
                    }else if(getPassword.length == 0){
                        setError(false);
                        setPasswordEnableError(true);
                        setNameEnableError(false);
                        setEmailEnableError(false);
                        setConfirmPasswordEnableError(false);
                        setPasswordErrorMessage('Enter Password');
                    }else if(getConfirmPassword.length == 0){
                        setError(false);
                        setConfirmPasswordEnableError(true);
                        setNameEnableError(false);
                        setEmailEnableError(false);
                        setPasswordEnableError(false);
                        setConfirmPasswordErrorMessage("Enter Confirm Password");
                    }else if(getPassword != getConfirmPassword){
                        setError(false);
                        setPasswordEnableError(true);
                        setPasswordErrorMessage('Password and confirm password must be equal');
                    }else if(getRole.length == 0 ||  getRole == 'none' ){
                        setError(true);
                        setErrorMessage('Select Role');
                    }else{
                        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                        if(emailRegex.test(getEmail)){
                            setError(false);
                            let selected = [];
                            selected = Array.from( selectedOptions, (option) => option.value);
                            console.log("===========selected:",selected);
                            params = { 
                                name: getName, 
                                email: getEmail, 
                                password: getPassword, 
                                role: sessionStorage.getItem('USER_ROLE') == '1' ? '2' :  getRole,
                                location:selected,
                                report_to:getRole != 5 ?  sessionStorage.getItem('USER_ID') : getManagers,
                                author:sessionStorage.getItem('USER_ID')
                            }; 
                            console.log("=======Params:",params);
                            MakeNetworkCall(postUsersUrls,'POST',params,addNewUserCallBack);
                        }else{
                                setEmailEnableError(true);
                                setEmailErrorMessage("Enter Valid Email");
                        }
                    }
                }else{
                    clearAllFields();
                    setVisible(false);
                    setErrorModelVisible(true);
                    setErrorModelMessage('Representative limit is restricted to 12');
                }
            } 



        } catch (error) { console.log('There was a problem with the API call:', error.toString()); }
    };
    
    const handleEditSubmit = async () => {
        try{
            var params = "";
            let selected = [];
            console.log("===========getStateHeadCount:",getStateHeadCount);
            console.log("===========getManagerCount:",getManagerCount);
            console.log("===========getRepresentativeCount:",getRepresentativeCount);
            console.log("===========getEditRole:",getEditRole);
                setEditError(false);
                selected = Array.from( getEditLocation, (option) => option.value);
                console.log("===========selected:",selected);
                params = { 
                    id:getId,
                    name: getEditName, 
                    email: getEditEmail, 
                    role: sessionStorage.getItem('USER_ROLE') == '1' ? '2' :  getEditRole,
                    location:selected,
                    report_to:getEditRole != 5 ?  sessionStorage.getItem('USER_ID') : getEditManagers,
                    author:sessionStorage.getItem('USER_ID')
                }; 
                console.log("=======Params:",params);
                MakeNetworkCall(postUpdateUserUrls,'POST',params,editUserCallBack);
        } catch (error) { console.log('There was a problem with the API call:', error.toString()); }
    };


// loader

const handleSaveClick = () => {
    handleNewSubmit();
  setIsLoading(true);
  setTimeout(() => {
    setIsLoading(false);  
  }, 3000); 
};

//edit loading

const handleloading_edit = () => {
    setEditError(false);
  setEdit_Loading(true);
  handleEditSubmit();
  setTimeout(() => {
    setIsLoading(false);  
  }, 3000);
};

async function addNewUser(){
    setEmailEnableError(false);
    setPasswordEnableError(false);
    setConfirmPasswordEnableError(false);
    setEdit_Loading(false);  
    clearAllFields();
    setVisible(true);
}


//password hide or show
const togglePasswordVisible=() =>{
   setShowPassword(!showPassword);
};

function setEditModelVisibility() {
    clearAllFields();
    setEdit(false);
    setEditError(false);
    setNameEnableError(false);
    setEmailEnableError(false);
    setPasswordEnableError(false);
    setConfirmPasswordEnableError(false);
    setEditName(false);
    setEditEmail(false);
    setEditLocationEnable(false);
    setEditStateHeadEnable(false);
    setEditManagersEnable(false);
}

function setNewModelVisibility(){
    clearAllFields();
    setEmailEnableError(false);
    setEmailEnableError(false);
    setRoleEnableError(false);
    setNameEnableError(false);
    setEmailEnableError(false);
    setPasswordEnableError(false);
    setConfirmPasswordEnableError(false);
    setLocationEnable(false);
    setStateHeadEnable(false);
    setManagersEnable(false);
    setVisible(false);
}

    return (

        <div>
        <Navbar activePage="adminlistusers" />
   <div className='bg-[#f8f8f9] px-[48px] min-h-[100vh] pb-[80px]   pt-[86px]
                  max-sm:px-[20px]  ' style={{fontFamily:'Montserrat, sans-serif'}} >

      <div className='w-auto h-auto '>
        <div className=' flex justify-between'>
         <h1 className='font-[600] text-[24px] leading-[29.26px] text-[#343434]
                         max-sm:text-[20px]'>Manage Users</h1>

      {screenWidth < 756 ?
      <button className="bg-[#F4CF7B] hover:bg-[#e7ca88] text-[#7A4310] font-[600]   px-2.5 py-1.5 text-[12px] border border-[#F4CF7B] rounded  "
      onClick={()=>{addNewUser()}}>
      
       <span className="material-symbols-outlined text-[18px] ">person_add</span>
       </button>
      :
         <button className="flex items-center bg-[#F4CF7B] hover:bg-[#dbba6c] h-[48px] w-[179px] text-[#7A4310] font-[600] text-[14px] py-2 px-4  rounded"
         onClick={()=>{addNewUser()}}>
            <span className="material-symbols-outlined mr-1  text-[20px]">person_add</span>Add New User</button>
      }
        </div>

        <div className='mt-[24px]'> 
        <div className='flex justify-center'>
        {loader? <div><div role="status"className=' '>
           <svg aria-hidden="true" className="w-8 h-8  text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
             <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
             <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
           </svg>
           <span className="sr-only">Loading...</span>
           </div></div>
          :""}
        
        </div>
        <div className= ' bottom-div-with-hidden-scrollbar h-auto   overflow-x-auto' >
  

        <table className="w-full text-sm text-left rtl:text-right">
            <thead className="bg-[#FFFAEE] text-[#393939] text-[14px] font-[500] h-[67px] leading-[16.94px] max-sm:text-[12px]"style={{whiteSpace:'nowrap'}} >
        <tr>
          <th scope="col" className="px-6 py-3">
            User Name
          </th>
          <th scope="col" className="px-6 py-3">
            User E-mail
          </th>
          <th scope="col" className="px-6 py-3">
            Role
          </th>
          <th scope="col" className="px-[45px] py-3">
          Action
          </th>
         
        </tr>
            </thead>
            {appendTableData()}
            
        </table>
    </div>
        </div>

      
      
      </div>
      <Modal onCancel = {()=> setNewModelVisibility()} 
        footer={null} 
        visible={visible}
        width={460}
        
        top={0}>
         <div className='pt-[20px] py-[24px]'style={{fontFamily:'Montserrat, sans-serif'}}>
        <div className='w-[auto] '>
            <h1 className='font-[600] text-[20px] leading-[24.38px] text-[#1C1C1C] mb-[24px]'>Add New User</h1>
            <hr></hr>

            {/* --------form------------ */}
            <div className='w-[auto] '>
            <div >
                    <div className="mb-4 mt-6">
                        <label className="block text-[#32324D] text-[12px] font-[400] leading-[16px] mb-2" >
                            User Name
                        </label>
                        <input className=" bg-[#FAFAFA] border border-[#E3E3E3] rounded w-full h-[40px] py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="username" type="text" placeholder='Eg: John Doe' value={getName}  onChange={handleNameChange} />
                        {getNameEnableError ? <p  className="relative self-center text-center text-[medium] not-italic text-[#dc3545]">{getNameErrorMessage}</p> : <div></div>}
                        </div>
                        <div className="mb-4">
                        <label className="block text-[#32324D] text-[12px] font-[400] leading-[16px] mb-2" >
                            User E-mail
                        </label>
                        <input className=" bg-[#FAFAFA] border border-[#E3E3E3] rounded w-full h-[40px] py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="username" type="email" placeholder='Eg: Johndoe@gmail.com'  value={getEmail} onChange={handleEmailChange}/>
                        {getEmailEnableError ? <p  className="relative self-center text-center text-[medium] not-italic text-[#dc3545]">{getEmailErrorMessage}</p> : <div></div>}
                        </div>
                        <div className="mb-4">
                        <label className="block text-[#32324D] text-[12px] font-[400] leading-[16px] mb-2" >
                            Password
                        </label>
                        <input className=" bg-[#FAFAFA] border border-[#E3E3E3] rounded w-full h-[40px] py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="username" type="password"  value={getPassword} onChange={handlePasswordChange}/>
                        {getPasswordEnableError ? <p  className="relative self-center text-center text-[medium] not-italic text-[#dc3545]">{getPasswordErrorMessage}</p> : <div></div>}
                        </div>
                        <div className="mb-4 relative">
                        <label className="block text-[#32324D] text-[12px] font-[400] leading-[16px] mb-2" >
                            Re-enter Password
                        </label>
                        <div className='relative   h-[40px]'> 
                        <input className=" bg-[#FAFAFA]  border border-[#E3E3E3] rounded w-full h-[40px] py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="username" type={showPassword?"test":"password"}  value={getConfirmPassword}  onChange={handleConfirmPasswordChange}/>
                        {getConfirmPasswordEnableError ? <p  className="relative self-center text-center text-[medium] not-italic text-[#dc3545]">{getConfirmPasswordErrorMessage}</p> : <div></div>}
                        <button onClick={togglePasswordVisible}>
                    
                        {showPassword?<img className='absolute w-[16px] right-3 top-3' src={Hide} alt=''/> :<img className='absolute w-[16px] right-3 top-3' src={Eye} alt=''/>}
                      </button>
                      </div>
                        </div>

                        <div className='mt-5'>
                            <h4 className='font-[600] text-[18px] leading-[22px] text-[#32324D]'>Roles</h4>
                            <div className="mb-4 mt-5">
                        <label className="block text-[#32324D] text-[12px] font-[400] leading-[16px] mb-2" >
                            User Roles
                        </label>
                        <div className='bg-[#FAFAFA]  border border-[#E3E3E3]  rounded w-full h-[40px] py-2 pl-3 pr-2'>
                          <select id="paid"  className=" bg-[#FAFAFA]  w-full   text-gray-700 leading-tight focus:outline-none focus:shadow-outline"  value={getRole} onChange={handleRoleChange} required>
                          <option  value="none"  className='my-5' selected>Choose Role</option>
                          {getRoleList.map((item, index) => (
                          <option value={item.id}>{item.name}</option>
                          ))}
                          </select>
                          </div>
                          {getRoleEnableError ? <p  className="relative self-center text-[small] not-italic text-[#dc3545]">{getRoleEnableErrorMessage}</p> : <div></div>}
                        </div>
                        {
                        getManagersEnable == 'true' ?
                        (
                        <div className="mb-4 mt-5">
                            <label className="block text-[#32324D] text-[12px] font-[400] leading-[16px] mb-2" >
                                Assign to manager
                            </label>
                            <div className='bg-[#FAFAFA]  border border-[#E3E3E3]  rounded w-full h-[40px] py-2 pl-3 pr-2'>
                            <select id="paid"  className=" bg-[#FAFAFA]  w-full   text-gray-700 leading-tight focus:outline-none focus:shadow-outline"  value={getManagers} onChange={handleManagersChange} >
                                <option  value="none" selected>Choose manager</option>
                                {getManagersList.map((item, index) => (<option value={item.id}>{item.name}</option>))}
                            </select>
                            </div>
                        </div>):(<div></div>)
                        }
                        {
                        getLocationEnable == 'true' ?
                        (<div className="mb-4 mt-5">
                            <label className="block text-[#32324D] text-[12px] font-[400] leading-[16px] mb-2" >
                                District
                            </label>
                            <div>
                                <Select
                                    isMulti
                                    value={selectedOptions}
                                    options={getDistrictsList}
                                    onChange={handleChange}
                                    styles={customStyles}
                                    placeholder="Choose a District"
                                />
                            </div>
                        </div>):(<div></div>)
                        }
                    </div>


                      
                       
                    {getError ? <p  className="relative self-center text-center text-[medium] not-italic text-[#dc3545]">{getErrorMessage}</p> : <div></div>}
                    
                    </div>
                    <div className='mt-[40px]  h-[49px] flex items-center relative'>
                            
                            <div className='absolute right-0 w-[203] h-[49px]   '>
                            <button className="bg-[#ffff] absolute right-[105px] hover:bg-[#fcfaf7] text-black font-[400] text-[14px]  px-4 border border-[#EBEBEB] rounded mr-3 mt-0 h-[49px] w-[98px]  " onClick={() => {
                                setNewModelVisibility()
                            }}>Cancel</button>
                            <button className='bg-[#F4CF7B] absolute right-0 hover:bg-[#e7ca88] text-[#7A4310] font-[600] text-[14px] border border-[#F4CF7B] w-[105px] h-[48px] rounded m-0 ' type={'submit'} onClick={handleSaveClick}>

                                        {isLoading ?  <div role="status"className='ml-[35px]  '>
                                        <svg aria-hidden="true" className="w-8 h-8  text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                                        <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
                                        </svg>
                                        <span className="sr-only">Loading...</span>
                                    </div>

                                        :'Save'}
                            </button>


                            </div>
                        </div>
            </div>

            {/* --------form  end------------ */}
        </div>
    </div>
    </Modal>



    {/*-----table edit-----modal  */}
    <Modal onCancel = {()=> setEditModelVisibility()} 
        footer={null} 
        visible={edit}
        width={460}
        top={0}>
     
     <div className='pt-[20px] py-[24px]'style={{fontFamily:'Montserrat, sans-serif'}}>
        <div className='w-[auto] '>
            <h1 className='font-[600] text-[20px] leading-[24.38px] text-[#1C1C1C] mb-[24px]'>Edit User</h1>
            <hr></hr>

            {/* --------form------------ */}
            <div className='w-[auto] '>
            <div >
                    <div className="mb-4 mt-6">
                        <label className="block text-[#32324D] text-[12px] font-[400] leading-[16px] mb-2" >
                            User Name
                        </label>
                        <input className=" bg-[#FAFAFA] border border-[#E3E3E3] rounded w-full h-[40px] py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="username" type="text" placeholder='Eg: John Doe'  value={getEditName}  onChange={handleEditNameChange} required />
                        </div>
                        <div className="mb-4">
                        <label className="block text-[#32324D] text-[12px] font-[400] leading-[16px] mb-2" >
                            User E-mail
                        </label>
                        <input className=" bg-[#FAFAFA] border border-[#E3E3E3] rounded w-full h-[40px] py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="username" type="email" placeholder='Eg: Johndoe@gmail.com' value={getEditEmail} onChange={handleEditEmailChange} required />
                        </div>
                        

                        {/* <div className='mt-5'>
                            <h4 className='font-[600] text-[18px] leading-[22px] text-[#32324D]'>Roles</h4>
                            <div className="mb-4 mt-5">
                                <label className="block text-[#32324D] text-[12px] font-[400] leading-[16px] mb-2" >
                                    User Roles
                                </label>
                                <div className='bg-[#FAFAFA]  border border-[#E3E3E3]  rounded w-full h-[40px] py-2 pl-3 pr-2'>
                                    <select id="paid"  className=" bg-[#FAFAFA]  w-full   text-gray-700 leading-tight focus:outline-none focus:shadow-outline"  value={getEditRole} onChange={handleEditRoleChange} required>
                                        <option  value="none"  className='my-5' selected>Choose Role</option>
                                        {getRoleList.map((item, index) => (<option value={item.id}>{item.name}</option>))}
                                    </select>
                                </div>
                            </div>
</div> */}
                        {
                        getEditManagersEnable == 'true' ?
                        (
                        <div className="mb-4 mt-5">
                            <label className="block text-[#32324D] text-[12px] font-[400] leading-[16px] mb-2" >
                            Assign to manager
                            </label>
                            <div className='bg-[#FAFAFA]  border border-[#E3E3E3]  rounded w-full h-[40px] py-2 pl-3 pr-2'>
                                <select id="paid"  className=" bg-[#FAFAFA]  w-full   text-gray-700 leading-tight focus:outline-none focus:shadow-outline"  value={getEditManagers} onChange={handleEditManagersChange} >
                                    <option  value="none" selectedden>Choose ...</option>
                                    {getManagersList.map((item, index) => (<option value={item.id}>{item.name}</option>))}
                                </select>
                            </div>

                        </div>):(<div></div>)
                        }

                        {
                        getEditLocationEnable == 'true' ?
                        (
                        <div className="mb-4 mt-5">
                            <label className="block text-[#32324D] text-[12px] font-[400] leading-[16px] mb-2" >
                                District
                            </label>
                            <div>
                                <Select
                                    isMulti
                                    value={getEditLocation}
                                    options={getDistrictsList}
                                    onChange={handleEditLocationChange}
                                    styles={customStyles}
                                    placeholder="Choose a District"
                                />
                            </div>
                        </div>):(<div></div>)
                        }
                        {getEditError ? <p  className="relative self-center text-center text-[medium] not-italic text-[#dc3545]">{getEditErrorMessage}</p> : <div></div>}
                        
 
                    </div>
                    <div className='mt-[40px]  h-[49px] flex items-center relative'>
                            <div className='absolute right-0 w-[203] h-[49px]   '>
                            <button className="bg-[#ffff] absolute right-[125px] hover:bg-[#fcfaf7] text-black font-[400] text-[14px]  px-4 border border-[#EBEBEB] rounded mr-3 mt-0 h-[49px] w-[98px]  " onClick={() => {setEditModelVisibility()}}>Cancel</button>
                            <button className='bg-[#F4CF7B] absolute right-0 hover:bg-[#e7ca88] text-[#7A4310] font-[600] text-[14px] border border-[#F4CF7B] w-[125px] h-[48px] px-2 rounded m-0 ' type={'submit'} onClick={handleloading_edit} >
                                {edit_Loading?  <div role="status"className='ml-[35px]  '>
                                    <svg aria-hidden="true" className="w-8 h-8  text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                                    <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
                                    </svg>
                                    <span className="sr-only">Loading...</span>
                                </div>

                                    :'Save change'}
                            </button>



                            </div>
                        </div>
            </div>

            {/* --------form  end------------ */}
        </div>
    </div>




    </Modal>

    {/* User Delete Model */}
    <Modal onCancel = {()=> setDelete(false)}  footer={null}  visible={getDelete} width={460} top={0}>
        <div className='pt-[20px] py-[24px]'style={{fontFamily:'Montserrat, sans-serif'}}>
            <div className='w-[auto] '>
                <h1 className='font-[600] text-[20px] leading-[24.38px] text-[#1C1C1C] mb-[24px]'>Delete User</h1>
                <hr></hr>
                <div className='w-[auto] '>
                <h3 className='font-[300] text-[18px] leading-[24.38px] text-[#1C1C1C] mb-[24px]'>Are you sure want to delete {getSelectUserName} user</h3>
                    <div className='mt-[40px]  h-[49px] flex items-center relative'>
                        <div className='absolute right-0 w-[203] h-[49px]   '>
                            <button className="bg-[#ffff] absolute right-[105px] hover:bg-[#fcfaf7] text-black font-[400] text-[14px]  px-4 border border-[#EBEBEB] rounded mr-3 mt-0 h-[49px] w-[98px] "onClick={() => setDelete(false)}>No</button>
                            <button className="bg-[#F4CF7B] absolute right-0 hover:bg-[#e7ca88] text-[#7A4310] font-[600] text-[14px] border border-[#F4CF7B] w-[105px] h-[48px] rounded m-0" onClick={() => deleteUsers()}>Yes</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Modal>

    <ErrorModel
          setVisible={setErrorModelVisible}
          getVisible={getErrorModelVisible}
          message={getErrorModelMessage}/>
   </div>
    </div>
    )

}